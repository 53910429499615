import React, {useEffect, useRef, useState} from "react";

import Layout from "@/components/Layout";
import {Form} from "@govtechsg/sgds-react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";
import FormulaModal from "@/components/FormulaModal";
import {toast} from "react-toastify";

import PersonalData from "@/components/AppHomes/TabContent/PersonalData";
import Application from "@/components/AppHomes/TabContent/Application";
import FamilyDetails from "@/components/AppHomes/TabContent/FamilyDetails";
import {
    getApplicationHomes,
    getMTCaseNotes,
    getMTCases,
    getMTCDetails,
    getMtConsent,
    getMTCRequestResult,
    postAppeal,
    postApplicationHomes,
    postQueryHomesRhhMtc
} from "@/apis/app-records/homes/api";
import {getListExercise} from "@/apis/preparation/app-timeline/api";
import {
    getApplicationList,
    getScholarship,
    upsertScholarship,
    deleteScholarship,
    getDetailApplicationLog, getScholarshipLog
} from "@/apis/app-records/maintenance/api";
import OriginalDetails from "@/components/AppHomes/TabContent/OriginalDetails";
import MTRequest from "@/components/AppHomes/TabContent/MTRequest";
import Appeal from "@/components/AppHomes/TabContent/Appeal";
import ScholarshipDetails from "@/components/AppHomes/TabContent/ScholarshipDetails";
import CustomModal from "@/components/CustomModal";
import {getParents} from "@/apis/app-records/parents/api";
import BreadCrumbHomes from "@/components/AppHomes/BreadCrumbHomes";
import FormFilterHomes from "@/components/AppHomes/FormFilterHomes";
import WrapperSwiperHomes from "@/components/AppHomes/WrapperSwiperHomes";
import EditAndSaveButtonHomes from "@/components/AppHomes/EditAndSaveButtonHomes";
import {
    getInitializeOptions,
    processGetListSiblingNYP,
    processGetMTCRequest,
    processGetStudentDetail,
    processTransferToAnotherExercise
} from "@/components/AppHomes/FunctionHomes";
import {sortExerciseList} from "@/components/utils/utils";
import {getAwardListWithParams} from '@/apis/preparation/setup-award/api'
import {getStudentDetail, saveStudentFinassists} from "@/apis/sims/api";
import {useSearchParams} from "react-router-dom";

import moment from "moment-timezone";

function AppHomes() {
    const MEANS_TEST_ID = null;
    const ACK_NO = null;
    const NYP_CONSENT_ID = null;
    const DETAIL_TAG = null;

    const [isOpen, setIsOpen] = useState(true);
    const contentRef = useRef(null);
    const [retrieved, setRetrieved] = useState(false);

    const firstTimeSubmitApplication = useRef(false);

    const [searchParams] = useSearchParams(); // useSearchParams hook
    const academicYearParams = searchParams.get("academicYearParams");
    const exerciseParams = searchParams.get("exerciseParams");
    const admNoParams = searchParams.get("admNoParams");

    const [isDisabled, setIsDisabled] = useState(true);
    const [scholarshipCount, setScholarshipCount] = useState([1]);
    const [siblingCount, setAddNewSiblingCount] = useState([1]);
    const [showFormulaModal, setShowFormulaModal] = useState(false);

    const [listAcadYears, setListAcadYears] = useState([]);
    const [listExercise, setListExercise] = useState([]);
    const [listAdmNo, setListAdmNo] = useState([]);

    const [listParent, setListParent] = useState([]);
    const [listSibling, setListSibling] = useState([]);
    const [listScholarship, setListScholarship] = useState([]);

    const [listMTCRequest, setListMTCRequest] = useState([]);
    const [listMTCRequestResult, setListMTCRequestResult] = useState([]);
    const [listMTCases, setListMTCases] = useState([]);
    const [listMTCaseNotes, setListMTCaseNotes] = useState([]);
    const [listMTCDetails, setListMTCDetails] = useState([]);
    const [tobeDeletedScholarship, setTobeDeletedScholarship] = useState([]);


    const [listMeanTestId, setListMeanTestId] = useState([])
    // MTC Details has 4 parts with diff DETAIL_TAG,
    // A is for Family details
    const [listMTCDetailsA, setListMTCDetailsA] = useState([])
    // Z is for Original details
    const [listMTCDetailsZ, setListMTCDetailsZ] = useState([])
    // P is for Appeal
    const [listMTCDetailsP, setListMTCDetailsP] = useState([])
    // M is for MT Request Tab
    const [listMTCDetailsM, setListMTCDetailsM] = useState([])


    const [details, setDetails] = useState([]);
    const [listMTIncomes, setMTIncomes] = useState([]);
    const [listMTConsents, setListMTConsents] = useState([]);
    const [listOriginalDetails, setListOriginalDetails] = useState([]);
    const [listMTCDetailsOriginal, setListMTCDetailsOriginal] = useState([]);
    const [listSiblingInNYP, setListSiblingInNYP] = useState([]);

    const [scholarshipDetail, setScholarshipDetail] = useState([]);
    const [
        showTransferToAnotherExerciseModal,
        setShowTransferToAnotherExerciseModal,
    ] = useState(false);
    const [transferToAnotherExerciseValue, setTransferToAnotherExerciseValue] =
        useState(0);

    // variable selected
    const [academicYear, setAcademicYear] = useState();
    const [selectedListExercise, setSelectedListExercise] = useState(null);
    const [selectedListAdmNo, setSelectedListAdmNo] = useState(null);
    const [applicationDetail, setApplicationDetail] = useState({});
    const [previousApplicationDetail, setPreviousApplicationDetail] = useState({});
    const [studentDetail, setStudentDetail] = useState({});
    const [maritalStatus, setMaritalStatus] = useState({label: null, value: null});
    const [dialectGroup, setDialectGroup] = useState({label: null, value: null});
    const [housingType, setHousingType] = useState({label: null, value: null});
    const [nypRemarks, setNYPRemarks] = useState([]);
    const [paRemarks, setPARemarks] = useState([]);
    const [selectedAwardType, setSelectedAwardType] = useState({
        label: null,
        value: null,
        code: null,
    });
    const [selectedAwardCode, setSelectedAwardCode] = useState({
        label: null,
        value: null,
    });

    const [bodyTab, setBodyTab] = useState({
        PersonalParticulars: true,
        Application: false,
        FamilyDetails: false,
        ResultDetails: false,
        OtherDetails: false,
        OriginalDetails: false,
        MTRequestDetails: false,
        Appeal: false,
    });

    //OPTIONS
    const [specialMeansStatusOptions, setSpecialMeansStatusOptions] = useState(
        []
    );
    const [residentialOptions, setResidentialOptions] = useState([]);
    const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
    const [consentTypeOptions, setConsentTypeOptions] = useState([]);
    const [consentScopeOptions, setConsentScopeOptions] = useState([]);
    const [consentStatusOptions, setConsentStatusOptions] = useState([]);
    const [legalCapacityOptions, setLegalCapacityOptions] = useState([]);
    const [mtRequestStatusOptions, setMtRequestStatusOptions] = useState([]);
    const [specialCaseOptions, setSpecialCaseOptions] = useState([]);
    const [careArrangementOptions, setCareArrangementOptions] = useState([]);
    const [relationshipOptions, setRelationshipOptions] = useState([]);
    const [statusTagOptions, setStatusTagOptions] = useState([]);
    const [caseTypeOptions, setCaseTypeOptions] = useState([]);
    const [occupationOptions, setOccupationOptions] = useState([]);
    const [specialCircumstanceOptions, setSpecialCircumstanceOptions] = useState([]);

    const [isExpandAll, setIsExpandAll] = useState(false);
    const [activeKeys, setActiveKeys] = useState([]);
    const [studentAwardDetail, setStudentAwardDetail] = useState({});

    const accordions = [
        "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10",
        "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21","22","23"
    ]

    const handleToggleAll = () => {
        if (isExpandAll) {
            setActiveKeys([]);
        } else {
            setActiveKeys(accordions.map(accordion => accordion));
        }
        setIsExpandAll(!isExpandAll);
    };

    const toggleCollapse = () => {
        setIsOpen(!isOpen);
    };

    const handleAcademicYearChange = (e) => {
        const newValue = e.value;
        if (newValue === null || /^[0-9\b]+$/.test(newValue)) {
            setAcademicYear(newValue);
            getListOfExercise(newValue);
        }
    };

    const handleListExerciseChange = (e) => {
        const newValue = e.value;
        if (newValue === null || /^[0-9\b]+$/.test(newValue)) {
            setSelectedListExercise(newValue);
        }
        getApplicationList({acad_yr: academicYear, exercise: newValue}).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        let listContent = [];
                        resp?.data?.data?.map((item) => {
                            if (listContent.some((e) => e?.value === item?.adm_no)) {
                            } else {
                                listContent.push({value: item?.adm_no, label: item?.adm_no});
                            }
                        });
                        setListAdmNo(listContent);
                    }
                }
            }
        );
    };

    const handleListAdmNoChange = (e) => {
        const newValue = e.value;
        setSelectedListAdmNo(newValue);
    };

    const handleToggleAccordion = (num) => {
        if (activeKeys.includes(num)) {
            setActiveKeys(activeKeys.filter(key => key !== num));
        } else {
            setActiveKeys([...activeKeys, num]);
        }
    };


    const handleClickEdit = () => {
        if (!retrieved) {
            toast.warning("No Data")
            return
        }
        setIsDisabled(!isDisabled);
    };

    const handleCancelEdit = () => {
        setIsDisabled(true);
        handleSubmit();
    }


    const getDetailofApplicationHomes = () => {
        getApplicationHomes(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
            // "240657P"
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                    setRetrieved(false)
                } else {
                    toast.error(resp?.response?.data?.message);
                    setRetrieved(false)
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
                setRetrieved(false)
            } else {
                if (resp.status == 200) {
                    setRetrieved(true)
                    if (resp?.data?.data?.length > 0) {
                        setApplicationDetail(resp?.data?.data[0]);
                        setPreviousApplicationDetail(resp?.data?.data[0]);
                        toast.success("Success get data");
                    }
                }
            }
            // setApplicationDetail(content);
        });
    };

    const getListOfExercise = (year) => {
        getListExercise(year, "Y").then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data?.length > 0) {
                        let listContent = [];
                        sortExerciseList(resp?.data?.data)?.map((item) => {
                            listContent.push({value: item?.exercise, label: item?.exercise});
                        });
                        setListExercise(listContent);

                    }
                }
            }
        });
    };

    const getDetailofStudentInformation = async () => {
        await processGetStudentDetail(selectedListAdmNo, academicYear, setStudentDetail)

        await getStudentDetail(selectedListAdmNo, academicYear, {
            fa_rec_yr: academicYear,
            fa_rec_award: applicationDetail?.rec_award_code,
            fa_rec_award_type: applicationDetail?.rec_award_type
        }).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setStudentAwardDetail(resp?.data?.data[0]);
                    }
                }
            }
        });
    };

    const getListSiblinginNYP = () => {
        processGetListSiblingNYP(
            selectedListAdmNo,
            academicYear,
            setListSiblingInNYP
        );
    };

    const getListMTCRequest = () => {
        processGetMTCRequest(
            academicYear,
            selectedListExercise,
            selectedListAdmNo,
            setListMTCRequest
        );
    };
    const getListMTCRequestResult = () => {
        getMTCRequestResult().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTCRequestResult(resp?.data?.data);
                    }
                }
            }
        });
    };
    const getListMTCaseNotes = () => {
        getMTCaseNotes().then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTCaseNotes(resp?.data?.data);
                    }
                }
            }
        });
    };

    const getListMTCDetails = () => {
        setListMeanTestId([])

        // FOR FAMILY
        getMTCDetails(academicYear, selectedListExercise, selectedListAdmNo, "A").then(
            async (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            // setListMTCDetails(resp?.data?.data);
                            const mtcDetails = resp?.data?.data;

                            const conceptAllMTC = await Promise.all(mtcDetails?.map(async (content, index) => {
                                const mtConsentResponse = await getMtConsent(content?.ack_no, null, "A", content?.hh_uin);

                                let updatedContent = {...content, mt_consent_details: []};

                                if (mtConsentResponse.status === 200 && mtConsentResponse?.data?.data) {
                                    updatedContent = {
                                        ...content,
                                        mt_consent_details: mtConsentResponse.data.data
                                    };
                                }

                                let mtRequestResultResponse = [];
                                let mtCasesResponse = [];
                                let mtCaseNotesResponse = [];

                                const postQueryResponse = await postQueryHomesRhhMtc({
                                    adm_no: selectedListAdmNo,
                                    hh_uin: content?.hh_uin
                                });

                                const MEAN_TEST_ID = postQueryResponse?.data?.data?.Data?.PastUsedMTCList?.[0]?.MeansTestID;

                                if (MEAN_TEST_ID) {
                                    mtRequestResultResponse = await getMTCRequestResult(null, MEAN_TEST_ID);
                                    mtCasesResponse = await getMTCases(MEAN_TEST_ID, null);
                                    mtCaseNotesResponse = await getMTCaseNotes(MEAN_TEST_ID, null);
                                }

                                return {
                                    ...updatedContent,
                                    dob: moment(content?.dob).tz('Asia/Singapore').format('YYYY-MM-DD'),
                                    mt_cases: mtCasesResponse?.data?.data,
                                    mt_case_notes: mtCaseNotesResponse.data?.data,
                                    mt_request_results: mtRequestResultResponse?.data?.data
                                };
                            }));


                            // const conceptAllMTC = await Promise.all(mtcDetails?.map(async (content, index) => {
                            //    return await getMtConsent(content?.ACK_NO, null, "A", content?.HH_UIN).then(async (resp) => {
                            //         if (resp.status == 200) {
                            //             if (resp?.data?.data) {
                            //                 if (resp?.data?.data) {
                            //                     const mtConsents = resp?.data?.data
                            //                     return {
                            //                         ...content,
                            //                         MT_CONSENT_DETAILS: mtConsents
                            //                     }
                            //                 }
                            //             } else {
                            //                 return {
                            //                     ...content,
                            //                     MT_CONSENT_DETAILS: []
                            //                 }
                            //
                            //             }
                            //         }
                            //     });
                            // }))

                            setListMTCDetailsA(conceptAllMTC)
                        }
                    }
                }
            }
        );

        // FOR ORIGINAL
        getMTCDetails(academicYear, selectedListExercise, selectedListAdmNo, "Z").then(
            async (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {

                            const mtcDetails = resp?.data?.data;

                            const conceptAllMTC = await Promise.all(mtcDetails?.map(async (content, index) => {
                                return await getMtConsent(content?.ack_no, null, "Z", content?.hh_uin).then(async (resp) => {
                                    if (resp.status == 200) {
                                        if (resp?.data?.data) {
                                            if (resp?.data?.data) {
                                                const mtConsents = resp?.data?.data
                                                return {
                                                    ...content,
                                                    mt_consent_details: mtConsents
                                                }
                                            }
                                        } else {
                                            return {
                                                ...content,
                                                mt_consent_details: []
                                            }

                                        }
                                    }
                                });
                            }))

                            setListMTCDetailsZ(conceptAllMTC)
                        }
                    }
                }
            }
        );

        // FOR APPEAL
        getMTCDetails(academicYear, selectedListExercise, selectedListAdmNo, "P").then(
            async (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {

                            const mtcDetails = resp?.data?.data;

                            const conceptAllMTC = await Promise.all(mtcDetails?.map(async (content, index) => {
                                return await getMtConsent(content?.ack_no, null, "P", content?.hh_uin).then(async (resp) => {
                                    if (resp.status == 200) {
                                        if (resp?.data?.data) {
                                            if (resp?.data?.data) {
                                                const mtConsents = resp?.data?.data
                                                return {
                                                    ...content,
                                                    dob: moment(content?.dob).format('YYYY-MM-DD'),
                                                    mt_consent_details: mtConsents
                                                }
                                            }
                                        } else {
                                            return {
                                                ...content,
                                                dob: moment(content?.dob).format('YYYY-MM-DD'),
                                                mt_consent_details: []
                                            }

                                        }
                                    }
                                });
                            }))

                            setListMTCDetailsP(conceptAllMTC)
                        }
                    }
                }
            }
        );

        // for MT REsults
        getMTCDetails(academicYear, selectedListExercise, selectedListAdmNo, "M").then(
            async (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            const mtcDetails = resp?.data?.data;

                            const conceptAllMTC = await Promise.all(mtcDetails?.map(async (content, index) => {
                                return await getMtConsent(content?.ack_no, null, "M", content?.hh_uin).then(async (resp) => {
                                    if (resp.status == 200) {
                                        if (resp?.data?.data) {
                                            if (resp?.data?.data) {
                                                const mtConsents = resp?.data?.data
                                                return {
                                                    ...content,
                                                    mt_consent_details: mtConsents
                                                }
                                            }
                                        } else {
                                            return {
                                                ...content,
                                                mt_consent_details: []
                                            }

                                        }
                                    }
                                });
                            }))

                            setListMTCDetailsM(conceptAllMTC)
                        }
                    }
                }
            }
        );
    };

    const getListMTCDetailsOriginal = () => {
        getMTCDetails(
            academicYear,
            selectedListExercise,
            selectedListAdmNo,
            "Z"
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setListMTCDetailsOriginal(resp?.data?.data);
                    }
                }
            }
        });
    };


    const getScholarshipDetail = () => {
        getScholarship(academicYear, selectedListExercise, selectedListAdmNo).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data) {
                            setScholarshipDetail(resp?.data?.data);
                        }
                    }
                }
            }
        );
    };

    const getFamilyDetails = () => {
        getParents(academicYear, selectedListExercise, selectedListAdmNo).then(
            (resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        if (resp?.data?.data?.length > 0) {
                            setListParent(resp?.data?.data);
                        } else {
                            // toast.warning("Parent Data is empty");
                            setListParent([
                                {
                                    relationship: "F",
                                    id: null,
                                    name: null,
                                    status: null,
                                    id_no: null,
                                    mthly_income_contrib: null,
                                    remark: null,
                                    child_support: null,
                                    birthdate: null,
                                    other_income: null,
                                    faas_occupations: null,
                                },
                                {
                                    relationship: "M",
                                    id: null,
                                    name: null,
                                    status: null,
                                    id_no: null,
                                    mthly_income_contrib: null,
                                    remark: null,
                                    child_support: null,
                                    birthdate: null,
                                    other_income: null,
                                    faas_occupations: null,
                                },
                            ]);
                        }
                    }
                }
            }
        );
    };

    const [originalApplicantDetails, setOriginalApplicantDetails] = useState({})
    const [listScholarshipLog, setListScholarshipLog] = useState([]);

    const handleGetOriginalApplicantDetail = () => {
        getDetailApplicationLog(
            academicYear,
            selectedListExercise,
            selectedListAdmNo
        ).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    setOriginalApplicantDetails(resp?.data?.data);
                }
            }
        });
    }

    const getScholarships = () => {
        try {
            getScholarshipLog(
                academicYear,
                selectedListExercise,
                selectedListAdmNo
            ).then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    if (resp.status == 200) {
                        setListScholarshipLog(resp?.data?.data);
                    }
                }
            });
            getScholarship(academicYear, selectedListExercise, selectedListAdmNo).then(
                (resp) => {
                    if (resp?.response?.data?.message) {
                        if (
                            resp?.response?.data?.message?.length > 0 &&
                            Array.isArray(resp?.response?.data?.message)
                        ) {
                            resp?.response?.data?.message?.map((contentErr) => {
                                toast.error(contentErr?.msg);
                            });
                        } else {
                            toast.error(resp?.response?.data?.message);
                        }
                    } else if (resp?.response?.status == 404) {
                        toast.error("Data not found");
                    } else {
                        if (resp.status == 200) {
                            const scholarshipDatas = resp?.data?.data;
                            let counterForBursary = 0;
                            let counterForScholarship = 0;
                            const newScholarship = scholarshipDatas?.map((content, index) => {
                                if (content?.rec_tag == "B") {
                                    return {
                                        ...content,
                                        counter: counterForBursary++
                                    }

                                } else {
                                    return {
                                        ...content,
                                        counter: counterForScholarship++
                                    }


                                }

                            })
                            setListScholarship(newScholarship);
                        }
                    }
                }
            );
        } catch (error) {
            console.log(error)
        }

    };

    const handleSubmit = () => {
        getDetailofApplicationHomes();
        getDetailofStudentInformation();
        handleGetOriginalApplicantDetail();

        getListMTCRequest();
        getListMTCRequestResult();
        // getListMTCases();

        // getListMTCaseNotes();
        getListMTCDetails();
        // getListMTIncomes();
        // getListMTConsents();
        // getListOriginalDetails();
        getScholarshipDetail();
        getListMTCDetailsOriginal();
        getListSiblinginNYP();
        getFamilyDetails();
        getScholarships();
    };

    const handleChange = (property, value) => {
        setApplicationDetail((prevState) => ({
            ...prevState,
            [property]: value,
        }));
    };

    const submitToApplicationHomes = () => {

        const data = {
            acad_yr: academicYear,
            exercise: selectedListExercise,
            adm_no: selectedListAdmNo,
            comcare: applicationDetail?.comcare,
            special_circumstances: applicationDetail?.special_circumstances,
            appln_status: applicationDetail?.appln_status,
            verify_status: applicationDetail?.verify_status,
            data_flag: applicationDetail?.data_flag,
            rec_award_type: selectedAwardType.value,
            rec_award_code: selectedAwardCode.value,
            request_type: applicationDetail?.request_type,

            recommended_date: previousApplicationDetail?.appln_status == null && applicationDetail?.appln_status === "R" ? moment(Date.now()).tz("Asia/Singapore").format("YYYY-MM-DD HH:mm:ss") : null,


            marital_status: applicationDetail?.marital_status,
            dialect_grp: applicationDetail?.dialect_grp,
            homehousetype: applicationDetail?.homehousetype,

            approval_status_reason: applicationDetail?.approval_status_reason,
            reason_for_changes: applicationDetail?.reason_for_changes,
            remark_desc: applicationDetail?.remark_desc,
            special_details: applicationDetail?.special_details,
            special_note: applicationDetail?.special_note,

            charge_in_court: applicationDetail.charge_in_court,
            remarks1: applicationDetail.remarks1,
            remark: applicationDetail.remark,
            cpf_approved_tag: applicationDetail.cpf_approved_tag,
            intact_family_tag: applicationDetail.intact_family_tag,
            comp_sponsor_tag: applicationDetail.comp_sponsor_tag,
            moe_subsidised: applicationDetail.moe_subsidised,

            //
            mpci: applicationDetail?.mpci,
            ccc_income: applicationDetail?.ccc_income,
            family_members: applicationDetail?.family_members,
            mtc_appeal_remark: applicationDetail?.mtc_appeal_remark,

            verify_status_by:
                applicationDetail?.verify_status_by === null &&
                applicationDetail?.verify_status !== null
                    ? sessionStorage.getItem("staffId")
                        ? sessionStorage.getItem("staffId")
                        : process.env.REACT_APP_LAST_UPD_ID
                    : applicationDetail?.verify_status_by,
            verify_status_date:
                applicationDetail?.verify_status_date === null &&
                applicationDetail?.verify_status !== null
                    ? moment(Date.now())
                        .tz("Asia/Singapore")
                        .format("yyyy-MM-DD HH:mm:ss")
                    : moment(applicationDetail?.verify_status_date).isValid()
                        ? moment(applicationDetail?.verify_status_date)
                            .tz("Asia/Singapore")
                            .format("yyyy-MM-DD HH:mm:ss")
                        : null,


            // DEPENDENT: "3",
            // INDEPEND_MTHLY_CONTRIB: "50",
            // CCC_INCOME: "100",
            // GROSS_INCOME: "200",
            // MPCI: "250",
            // FPCI: "300",
            // CHARGE_IN_COURT: "Y",
            // APPLN_DETAILS: null,
            // APPLN_STATUS: "D",
            // REASON_FOR_CHANGES: null,
            // REC_AWARD_TYPE: null,
            // REC_AWARD_CODE: null,
            // SPECIAL_DETAILS: null,
            // ACK_NO: "ACK1234",
            // INTACT_FAMILY_TAG: null,
            // FAMILY_MEMBERS: "4",
            // SPECIAL_NOTE: null,
            // SCHOLAR_SPONSOR_TAG: "Y",
            // DIALECT_GRP: "group A",
            // HOMEHOUSETYPE: "reg",
            // MTC_APPEAL_REMARK: null,
            // SPECIAL_CASE: null,
            // REMARK: null,
            // REMARKS1: null,
            // LAST_UPD_ID: "141517A",
            // MTC_DETAILS: [
            //   {
            //     DETAIL_TAG: "A",
            //     ACK_NO: "ACK1234",
            //     ACAD_YR: "2041",
            //     FAAS_EXERCISE: "1",
            //     NBFA_EXERCISE: "1",
            //     ADM_NO: "141517A",
            //     HH_UIN: "S6876111E",
            //     HH_NAME: "Ong Mary",
            //     OTHER_INCOME: null,
            //     MARITAL_STATUS: null,
            //     RES_STATUS: "A",
            //     DOB: "2022-01-23",
            //     GENDER: "F",
            //     MTC_TAG: null,
            //     RELATIONSHIP: "HRC002",
            //     SPECIAL_MEANS_STATUS: null,
            //     ISNOVALIDAUTH: null,
            //     REMARKS: null,
            //     MTREQUESTID: null,
            //     MEANS_TEST_ID: null,
            //     STATUS_TAG: null,
            //     CHANGE_TYPE: null,
            //     INCOME_PERIOD: null,
            //     AVG_MTHLY_INCOME: null,
            //     MT_MEMBER_INDICATORS: null,
            //     OCCUPATION: null,
            //     REUSABLE_CONSENTID: null,
            //     REUSABLE_CONSENTSCOPE: null,
            //   },
            // ],
            // SCHOLARSHIPS: [
            //   {
            //     SCHOLAR_SPONSOR: "MMM Foundation",
            //     TUTFEE_C_TAG: "Y",
            //     L_ALLOWANCE_C_TAG: "Y",
            //     L_ALLOWANCE_C_DETAILS: "0",
            //     OTHERS_C_TAG: "Y",
            //     OTHERS_C_DETAILS: null,
            //     L_ALLOWANCE_C_TYPE: null,
            //     LAST_UPD_ID: "236",
            //     REC_TAG: "B",
            //     GOVBUR_QUANTUM: "3000",
            //     GOVBUR_PERIOD: "Yearly",
            //   },
            // ],
        };

        let mergedData;
        if (listMTCRequest.length > 0) {
            mergedData = {...data, mt_request_id: listMTCRequest[0].ID};
        } else {
            mergedData = data;
        }

        let contentFinalData = {
            data: [mergedData],
        };

        postApplicationHomes(contentFinalData).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {


                const appln_status = applicationDetail?.appln_status;
                if (appln_status == "A") {
                    // get amount awarded
                    getAwardListWithParams(selectedAwardType.value, selectedAwardCode.value).then((resp) => {
                        if (resp.status == 200) {
                            const quantumValue = resp?.data?.data?.[0]?.quantum;

                            // submit awarded to finanists
                            saveStudentFinassists([{
                                adm_no: selectedListAdmNo,
                                award: selectedAwardCode.value,
                                award_type: selectedAwardType.value,
                                year_awarded: academicYear,
                                amt_awarded: quantumValue,
                                action: "I",
                                last_upd_id: sessionStorage.getItem("staffId")
                                    ? sessionStorage.getItem("staffId")
                                    : process.env.REACT_APP_LAST_UPD_ID
                            },])


                        }
                    })
                }


                toast.success("Application HOMES has been saved");
            }
        });
    };

    const submitTransferToAnotherExercise = () => {
        processTransferToAnotherExercise(
            academicYear,
            selectedListAdmNo,
            selectedListExercise,
            transferToAnotherExerciseValue
        );
    };

    const submitAppealForm = () => {
        let mergeFromFamilyAndAppeal = [...listMTCDetailsA, ...listMTCDetailsP]

        mergeFromFamilyAndAppeal = mergeFromFamilyAndAppeal.map((item) => {
            if (item.mt_consent_details && Array.isArray(item.mt_consent_details)) {
                item.mt_consent_details = item.mt_consent_details.map((detail) => {
                    if (detail.nyp_consent_date) {
                        // Check if the date contains a time part
                        if (detail.nyp_consent_date.includes(" ")) {
                            const dateParts = detail.nyp_consent_date.split(" ");
                            detail.nyp_consent_date = dateParts[0];
                        }
                    }
                    return detail;
                });
            }
            return item;
        });

        postAppeal(mergeFromFamilyAndAppeal).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                toast.success("Family & Appeal updated successfully");
            }
        });
    };

    const submitToScholarships = () => {
        const updatedScholarshipDetail = scholarshipDetail.map(item => ({
            ...item,
            acad_yr: academicYear,
            exercise: selectedListExercise,
            adm_no: selectedListAdmNo,
        }));

        upsertScholarship({data: updatedScholarshipDetail})
            .then((resp) => {
                if (resp?.response?.data?.message) {
                    if (
                        resp?.response?.data?.message?.length > 0 &&
                        Array.isArray(resp?.response?.data?.message)
                    ) {
                        resp?.response?.data?.message?.map((contentErr) => {
                            toast.error(contentErr?.msg);
                        });
                    } else {
                        toast.error(resp?.response?.data?.message);
                    }
                } else if (resp?.response?.status == 404) {
                    toast.error("Data not found");
                } else {
                    toast.success("Scholarship updated successfully");
                }
            })
            .catch((error) => {
                console.log(error);
            });

            if (tobeDeletedScholarship.length > 0) {
                tobeDeletedScholarship.forEach((id) => {
                    deleteScholarship(id).then(() => {
                        console.log("Deleted");
                    });
                });
            }

    };

    const submitToStudFinAssist = () => {
        if (applicationDetail?.appln_status == "A") {
            // get amount awarded
            getAwardListWithParams(selectedAwardType.value, selectedAwardCode.value).then((resp) => {
                if (resp.status == 200) {
                    const quantumValue = resp?.data?.data?.[0]?.quantum;

                    // submit awarded to finanists
                    saveStudentFinassists([{
                        adm_no: selectedListAdmNo,
                        award: selectedAwardCode.value,
                        award_type: selectedAwardType.value,
                        year_awarded: academicYear,
                        amt_awarded: quantumValue,
                        action: "U",
                        last_upd_id: sessionStorage.getItem("staffId")
                            ? sessionStorage.getItem("staffId")
                            : process.env.REACT_APP_LAST_UPD_ID
                    },])


                }
            })
        }
    }

    const handleChangeListScholarship = (field, value, index) => {
        setScholarshipDetail((prevItems) => {
            const updatedItems = [...prevItems];
            updatedItems[index] = {...updatedItems[index], [field]: value};
            return updatedItems;
        });
    };

    const handleSubmitApplicationForm = () => {
        setIsDisabled(!isDisabled);

        if (
            applicationDetail?.appln_status === "R" &&
            !selectedAwardType.value &&
            !selectedAwardCode.value
        ) {
            toast.warning("Recommended Award Type and Award must be filled");
            return false;
        }

        if (
            applicationDetail?.appln_status === "A" &&
            !selectedAwardType.value &&
            !selectedAwardCode.value
        ) {
            toast.warning("Recommended Award Type and Award must be filled");
            return false;
        }


        Promise.all([submitToApplicationHomes(), submitAppealForm(), submitToScholarships(), submitToStudFinAssist()]).then(() => {
            setTimeout(() => {
                handleSubmit()
            }, 1000)
            toast.success("Application has been saved");
        })
        // submitToApplicationHomes();
        // submitAppealForm();
        // submitToScholarships();
    };

    useEffect(() => {
        getInitializeOptions(
            setListAcadYears,
            setListAdmNo,
            setNYPRemarks,
            setPARemarks,
            setSpecialMeansStatusOptions,
            setResidentialOptions,
            setMaritalStatusOptions,
            setConsentScopeOptions,
            setLegalCapacityOptions,
            setConsentTypeOptions,
            setConsentStatusOptions,
            setMtRequestStatusOptions,
            setSpecialCaseOptions,
            setCareArrangementOptions,
            setRelationshipOptions,
            setStatusTagOptions,
            setCaseTypeOptions,
            setOccupationOptions,
            setSpecialCircumstanceOptions,
        );
    }, []);

    useEffect(() => {
        if (academicYearParams) {
            setAcademicYear(academicYearParams);
        }
        if (exerciseParams) {
            setSelectedListExercise(exerciseParams);
        }
        if (admNoParams) {
            setSelectedListAdmNo(admNoParams);
        }
    }, [searchParams]);


    useEffect(() => {
        if (
            academicYear &&
            selectedListExercise &&
            selectedListAdmNo &&
            !firstTimeSubmitApplication.current &&
            academicYearParams &&
            exerciseParams &&
            admNoParams
        ) {
            handleSubmit();
            firstTimeSubmitApplication.current = true;
        }
    }, [academicYear, selectedListExercise, selectedListAdmNo]);


    return (
        <Layout currentNav={"app-records"}>
            <BreadCrumbHomes/>

            <sgds-content-header-top>
                <h2>Application HOMES</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <FormFilterHomes
                        contentRef={contentRef}
                        isOpen={isOpen}
                        listAcadYears={listAcadYears}
                        listExercise={listExercise}
                        listAdmNo={listAdmNo}
                        handleSubmit={handleSubmit}
                        handleAcademicYearChange={handleAcademicYearChange}
                        handleListExerciseChange={handleListExerciseChange}
                        handleListAdmNoChange={handleListAdmNoChange}
                        academicYear={academicYear}
                        selectedListExercise={selectedListExercise}
                        selectedListAdmNo={selectedListAdmNo}
                    />

                    <button
                        onClick={toggleCollapse}
                        className="btn-clear text-blue ps-0 mt-3"
                    >
                        {isOpen ? "Hide " : "Expand "}{" "}
                        {isOpen ? <FaChevronUp/> : <FaChevronDown/>}
                    </button>
                </div>

                <WrapperSwiperHomes bodyTab={bodyTab} setBodyTab={setBodyTab}/>

                {/* Tab content */}
                {bodyTab.PersonalParticulars && (
                    <React.Fragment>
                        <div className="d-flex justify-content-between">
                            {isDisabled ? (
                                <button
                                    className="bg-white border-1 px-3 rounded-2 "
                                    onClick={handleClickEdit}
                                >
                                    <i className={`bi bi-pencil`}></i>
                                    {"  "}Edit
                                </button>
                            ) : (
                                <div className={`d-flex gap-3`}>
                                    <button
                                        className="bg-white border-1 px-3 rounded-2 text-green-500"
                                        onClick={handleSubmitApplicationForm}
                                    >
                                        <i className={`bi bi-check-lg`}></i>
                                        {"  "}Save
                                    </button>
                                    <button
                                        className="bg-white border-1 px-3 rounded-2 text-red-500"
                                        onClick={handleCancelEdit}
                                    >
                                        <i className={`bi bi-x-circle`}></i>
                                        {"  "}Cancel
                                    </button>
                                </div>
                            )}
                            <button
                                className="bg-white border-1 px-3 rounded-2 "
                                onClick={handleToggleAll}
                            >
                                {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                {" "}
                                {!isExpandAll ? "Expand All" : "Collapse All"}
                            </button>
                        </div>

                        <PersonalData
                            applicationDetail={applicationDetail}
                            setApplicationDetail={setApplicationDetail}
                            studentDetail={studentDetail}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            options
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            academicYear={academicYear}
                            handleAcademicYearChange={handleAcademicYearChange}
                            maritalStatus={maritalStatus}
                            setMaritalStatus={setMaritalStatus}
                            dialectGroup={dialectGroup}
                            setDialectGroup={setDialectGroup}
                            housingType={housingType}
                            setHousingType={setHousingType}
                            handleChange={handleChange}
                            activeKeys={activeKeys}
                            handleToggleAccordion={handleToggleAccordion}
                            specialCircumstanceOptions={specialCircumstanceOptions}
                        />
                    </React.Fragment>
                )}

                {bodyTab.Application && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                            handleToggleAll={handleToggleAll}
                            isExpandAll={isExpandAll}
                            handleCancelEdit={handleCancelEdit}
                        />

                        <Application
                            applicationDetail={applicationDetail}
                            setApplicationDetail={setApplicationDetail}
                            studentDetail={studentDetail}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            options
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            academicYear={academicYear}
                            handleAcademicYearChange={handleAcademicYearChange}
                            maritalStatus={maritalStatus}
                            setMaritalStatus={setMaritalStatus}
                            dialectGroup={dialectGroup}
                            setDialectGroup={setDialectGroup}
                            housingType={housingType}
                            setHousingType={setHousingType}
                            handleChange={handleChange}
                            listMTCRequest={listMTCRequest}
                            selectedAwardType={selectedAwardType}
                            selectedAwardCode={selectedAwardCode}
                            setSelectedAwardType={setSelectedAwardType}
                            setSelectedAwardCode={setSelectedAwardCode}
                            setShowTransferToAnotherExerciseModal={
                                setShowTransferToAnotherExerciseModal
                            }
                            nypRemarks={nypRemarks}
                            paRemarks={paRemarks}
                            activeKeys={activeKeys}
                            handleToggleAccordion={handleToggleAccordion}
                            listMTCDetailsM={listMTCDetailsM}
                            studentAwardDetail={studentAwardDetail}
                        />
                    </React.Fragment>
                )}

                {bodyTab.FamilyDetails && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={false}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                            handleToggleAll={handleToggleAll}
                            isExpandAll={isExpandAll}
                            handleCancelEdit={handleCancelEdit}
                            isShowCancelButton={false}
                        />

                        <FamilyDetails
                            ACK_YR={academicYear}
                            ACK_NO={ACK_NO}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            isDisabled={false}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listOriginalDetails={listOriginalDetails}
                            listMTCDetails={listMTCDetails}
                            listSiblingInNYP={listSiblingInNYP}
                            scholarshipDetail={scholarshipDetail}
                            studentDetail={studentDetail}
                            handleClickEdit={() => setIsDisabled(!isDisabled)}
                            details={details}
                            setDetails={setDetails}
                            specialMeansStatusOptions={specialMeansStatusOptions}
                            maritalStatusOptions={maritalStatusOptions}
                            residentialOptions={residentialOptions}
                            consentTypeOptions={consentTypeOptions}
                            consentScopeOptions={consentScopeOptions}
                            consentStatusOptions={consentStatusOptions}
                            legalCapacityOptions={legalCapacityOptions}
                            relationshipOptions={relationshipOptions}
                            occupationOptions={occupationOptions}
                            listMTCDetailsA={listMTCDetailsA}
                            setListMTCDetailsA={setListMTCDetailsA}
                            activeKeys={activeKeys}
                            handleToggleAccordion={handleToggleAccordion}
                            handleApplicationChange={handleChange}
                        />
                    </React.Fragment>
                )}

                {bodyTab.OtherDetails && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                            handleToggleAll={handleToggleAll}
                            isExpandAll={isExpandAll}
                            handleCancelEdit={handleCancelEdit}

                        />
                        <ScholarshipDetails
                            isDisabled={isDisabled}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listOriginalDetails={listOriginalDetails}
                            listMTCDetails={listMTCDetails}
                            scholarshipDetail={scholarshipDetail}
                            studentDetail={studentDetail}
                            handleClickEdit={() => setIsDisabled(!isDisabled)}
                            handleChange={handleChange}
                            handleChangeListScholarship={handleChangeListScholarship}
                            setScholarshipDetail={setScholarshipDetail}
                            submitToScholarships={submitToScholarships}
                            activeKeys={activeKeys}
                            handleToggleAccordion={handleToggleAccordion}
                            handleChangeTobeDeleted={(ids) =>
                                setTobeDeletedScholarship(ids)}
                            tobeDeletedScholarship={tobeDeletedScholarship}
                        />
                    </React.Fragment>
                )}

                {bodyTab.OriginalDetails && (
                    <React.Fragment>
                        <div className="d-flex justify-content-between">
                            <div></div>
                            <button
                                className="bg-white border-1 px-3 rounded-2 "
                                onClick={handleToggleAll}
                            >
                                {isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                {" "}
                                {isExpandAll ? "Expand All" : "Collapse All"}
                            </button>
                        </div>

                        <OriginalDetails
                            isDisabled={isDisabled}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listOriginalDetails={listOriginalDetails}
                            details={originalApplicantDetails}
                            listScholarshipLog={listScholarshipLog}
                            listMTCDetails={listMTCDetails}
                            listMTCDetailsOriginal={listMTCDetailsOriginal}
                            scholarshipDetail={scholarshipDetail}
                            listMTCDetailsZ={listMTCDetailsZ}
                            specialMeansStatusOptions={specialMeansStatusOptions}
                            maritalStatusOptions={maritalStatusOptions}
                            residentialOptions={residentialOptions}
                            consentTypeOptions={consentScopeOptions}
                            consentScopeOptions={consentScopeOptions}
                            consentStatusOptions={consentStatusOptions}
                            legalCapacityOptions={legalCapacityOptions}
                            relationshipOptions={relationshipOptions}
                            occupationOptions={occupationOptions}
                            activeKeys={activeKeys}
                            handleToggleAccordion={handleToggleAccordion}

                        />
                    </React.Fragment>
                )}

                {bodyTab.MTRequestDetails && (
                    <React.Fragment>
                        <div className="d-flex justify-content-between">
                            <div></div>
                            <button
                                className="bg-white border-1 px-3 rounded-2 "
                                onClick={handleToggleAll}
                            >
                                {isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                                {" "}
                                {isExpandAll ? "Expand All" : "Collapse All"}
                            </button>
                        </div>


                        <MTRequest
                            isDisabled={true}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listMTCDetails={listMTCDetails}
                            details={details}
                            listMTCDetailsM={listMTCDetailsM}
                            specialMeansStatusOptions={specialMeansStatusOptions}
                            maritalStatusOptions={maritalStatusOptions}
                            residentialOptions={residentialOptions}
                            consentTypeOptions={consentScopeOptions}
                            consentScopeOptions={consentScopeOptions}
                            consentStatusOptions={consentStatusOptions}
                            legalCapacityOptions={legalCapacityOptions}
                            relationshipOptions={relationshipOptions}
                            occupationOptions={occupationOptions}
                            listMTCDetailsA={listMTCDetailsA}
                            activeKeys={activeKeys}
                            handleToggleAccordion={handleToggleAccordion}

                        />
                    </React.Fragment>
                )}

                {bodyTab.Appeal && (
                    <React.Fragment>
                        <EditAndSaveButtonHomes
                            isDisabled={isDisabled}
                            handleClickEdit={handleClickEdit}
                            handleSubmitApplicationForm={handleSubmitApplicationForm}
                            handleToggleAll={handleToggleAll}
                            isExpandAll={isExpandAll}
                            handleCancelEdit={handleCancelEdit}

                        />

                        <Appeal
                            handleClickEdit={() => setIsDisabled(!isDisabled)}
                            ACK_YR={academicYear}
                            ACK_NO={ACK_NO}
                            isDisabled={isDisabled}
                            applicationDetail={applicationDetail}
                            listMTIncomes={listMTIncomes}
                            listMTConsents={listMTConsents}
                            listMTCases={listMTCases}
                            listMTCaseNotes={listMTCaseNotes}
                            listMTCRequestResult={listMTCRequestResult}
                            listMTCDetails={listMTCDetails}
                            familyDetails={details}
                            details={details}
                            setDetails={setDetails}
                            selectedListExercise={selectedListExercise}
                            selectedListAdmNo={selectedListAdmNo}
                            specialMeansStatusOptions={specialMeansStatusOptions}
                            maritalStatusOptions={maritalStatusOptions}
                            residentialOptions={residentialOptions}
                            consentTypeOptions={consentTypeOptions}
                            consentScopeOptions={consentScopeOptions}
                            consentStatusOptions={consentStatusOptions}
                            legalCapacityOptions={legalCapacityOptions}
                            relationshipOptions={relationshipOptions}
                            occupationOptions={occupationOptions}
                            listMTCDetailsP={listMTCDetailsP}
                            setListMTCDetailsP={setListMTCDetailsP}
                            activeKeys={activeKeys}
                            listMTCDetailsA={listMTCDetailsA}
                            handleToggleAccordion={handleToggleAccordion}
                        />
                    </React.Fragment>
                )}
            </sgds-content-body>

            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />

            {/* Transfer to Another Exercise Modal */}
            <CustomModal
                show={showTransferToAnotherExerciseModal}
                handleClose={() =>
                    setShowTransferToAnotherExerciseModal(
                        !showTransferToAnotherExerciseModal
                    )
                }
                title="Transfer To Another Exercise"
                description={
                    <>
                        <div>
                            <p>Current Info:</p>
                            <div>
                                Academy Year: {academicYear}
                                <br/>
                                Exercise: {selectedListExercise}
                                <br/>
                                Adm. No.: {selectedListAdmNo}
                            </div>
                        </div>
                        <Form.Group>
                            <Form.Label>Transfer to Exercise</Form.Label>
                            <Form.Control
                                type={`text`}
                                // disabled={true}
                                value={transferToAnotherExerciseValue}
                                onChange={(e) =>
                                    setTransferToAnotherExerciseValue(e.target.value)
                                }
                            ></Form.Control>
                        </Form.Group>
                    </>
                }
                action={() => submitTransferToAnotherExercise()}
                submitButtonText={"Submit"}
            />
        </Layout>
    );
}

export default AppHomes;
