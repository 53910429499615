import React, {useEffect, useState} from "react";

import {Col, Form, Row} from "@govtechsg/sgds-react";
import Select from "react-select";
import {ApplicationStatus, DataFlagStatus, VerificationStatus,} from "@/apis/app-records/maintenance/status";
import {getCodeTypeSasList} from "@/apis/sims/api";
import {toast} from "react-toastify";
import {getRemarkPA} from "@/apis/preparation/setup-remarks/api";
import moment from "moment";


function ApplicationDetail({
                               data,
                               studentDetail,
                               isDisabled,
                               selectedVerificationStatus,
                               setSelectedVerificationStatus,
                               selectedApplicationStatus,
                               setSelectedApplicationStatus,
                               selectedDataFlag,
                               setSelectedDataFlag,
                               selectedAwardType,
                               setSelectedAwardType,
                               selectedAwardCode,
                               setSelectedAwardCode,
                               textReasonForChanges,
                               setTextReasonForChanges,
                               textRemarkS1,
                               setTextRemarkS1,
                               textApprovalRemarks,
                               setTextApprovalRemarks,
                               textApprovalStatusSpecialRemarks,
                               setTextApprovalStatusSpecialRemarks,
                               textSpecialDetailRemarks,
                               setTextSpecialDetailRemarks,
                               PARemarks,
                               NYPRemarks,
                               setNypRemark,
                               setPaRemark,
                               textDSpecialDetailRemarks,
                               handleApplicationChange,
                               successApplication
                           }) {
    const [academicYear, setAcademicYear] = useState(null);

    // define variable

    // define list
    const [awardTypeList, setAwardTypeList] = useState([]);
    const [awardCodeList, setAwardCodeList] = useState([]);
    const [PAOptions, setPAOptions] = useState([]);
    const [NYPOptions, setNYPOptions] = useState([]);
    const [selectedPAOption, setSelectedPAOption] = useState(null);
    const [selectedNYPOption, setSelectedNYPOption] = useState(null);
    const [remapVerifOptions, setRemapVerifOptions] = useState([]);
    const [remapApplOptions, setRemapApplOptions] = useState([]);
    const [selectedReasonForChanges, setSelectedReasonForChanges] =
        useState(null);
    const [reasonOptions, setReasonOptions] = useState([]);

    const roleList = JSON.parse(sessionStorage.getItem("roles")) || [];

    const handleClickEdit = () => {
        setIsDisabled(!isDisabled);
    };

    useEffect(() => {
        setSelectedVerificationStatus({
            label: data?.verify_status
                ? VerificationStatus().find(
                    (option) => option.value === data?.verify_status
                )
                    ? `${
                        VerificationStatus().find(
                            (option) => option.value === data?.verify_status
                        ).label
                    }`
                    : ""
                : "",
            value: data?.verify_status,
        });

        setSelectedApplicationStatus({
            label: data?.appln_status ? ApplicationStatus().find(
                    (option) => option.value === data?.appln_status
                )
                    ? `${
                        ApplicationStatus().find(
                            (option) => option.value === data?.appln_status
                        ).label
                    }`
                    : ""
                : "",
            value: data?.appln_status,
        });

        setSelectedDataFlag({
            label: data?.data_flag ? DataFlagStatus().find(
                    (option) => option.value === data?.data_flag
                )
                    ? `${
                        DataFlagStatus().find(
                            (option) => option.value === data?.data_flag
                        ).label
                    }`
                    : ""
                : "",
            value: data?.data_flag,
        });

        setSelectedAwardType({
            label: "",
            value: data?.rec_award_type,
        });

        setSelectedAwardCode({
            label: "",
            value: data?.rec_award_code,
        });

        // Approval status remarks (NYP)
        setSelectedNYPOption({
            label: NYPRemarks?.map((content) => {
                if (content?.code == data?.remark) {
                    return content?.code + " - " + content?.remark;
                }
            }),
            value: data?.remark,
        });

        // Approval status remarks (PA)
        setSelectedPAOption({
            label: data?.approval_status_reason,
            value: data?.approval_status_reason,
        });

        // Special Note == Approval Status Special Remarks
        setTextApprovalStatusSpecialRemarks(data?.special_note);
        // Special details == Special Details Remark
        setTextSpecialDetailRemarks(data?.special_details);
        setTextReasonForChanges(data?.reason_for_changes);
        // setTextApprovalRemarks(data?.REMARKS1);

        // setTextRemarkS1(data?.REMARKS1);

        getCodeTypeSasList("AWARDTYPECODE").then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.code,
                    code: content.code,
                });

                if (data?.rec_award_type == content.code) {
                    setSelectedAwardType({
                        label: content.description,
                        value: content.code,
                        code: content.code,
                    });
                }
            });
            setAwardTypeList(contentArray);
        });

        getCodeTypeSasList(selectedAwardType.code).then((resp) => {
            let contentArray = [];
            resp?.data?.data?.map((content) => {
                contentArray.push({
                    label: content.description,
                    value: content.code,
                });
                if (data?.rec_award_code == content.code) {
                    setSelectedAwardCode({
                        label: content.description,
                        value: content.code,
                    });
                }
            });
            setAwardCodeList(contentArray);
        });
    }, [
        data?.verify_status,
        data?.appln_status,
        data?.data_flag,
        data?.rec_award_type,
        data?.rec_award_code,
        data?.reason_for_changes,
        data?.remarks1,
        data?.remark,
        data?.approval_status_reason,
        data?.special_note,
        successApplication
    ]);

    useEffect(() => {
        getCodeTypeSasList(selectedAwardType.code).then((resp) => {
            let content = [];
            resp?.data?.data?.map((data) => {
                content.push({
                    label: data.description,
                    value: data.code,
                });
            });
            setAwardCodeList(content);
        });
    }, [selectedAwardType]);

    useEffect(() => {
        const newNYPOptions = NYPRemarks?.map((remark) => ({
            label: `${remark.code} - ${remark.remark}`,
            value: remark.code,
        }));

        setNYPOptions(newNYPOptions);

        const newPAOptions = PARemarks?.map((remark) => ({
            label: remark.sts_reason,
            value: remark.sts_reason,
        }));

        setPAOptions(newPAOptions);
    }, [PARemarks, NYPRemarks]);

    useEffect(() => {
        if (data) {
            const matchingOption = NYPOptions?.find(
                (option) => option.value === data.reason_for_changes
            );
            if (matchingOption) {
                setSelectedNYPOption(matchingOption);
            }

            const PAmatchingOption = PAOptions?.find(
                (option) => option.value === data.approval_status_reason
            );
            if (PAmatchingOption) {
                setSelectedPAOption(PAmatchingOption);
            }
        }
    }, [data, NYPOptions, PAOptions]);

    useEffect(() => {
        const updatedApplOptions = ApplicationStatus().map((status) => {
            let isDisabled = true;
            // let isDisabled = false;

            //Application status can only be set to 'N' if the verification status = Exclude
            if (
                selectedVerificationStatus?.value === "E"
                ) {
                    if ((status.value === "N" || status.value == null)) {
                        isDisabled = false;
                    }
            }

            if (selectedVerificationStatus?.value == "I") {
                if (data?.appln_status == "A" || data?.appln_status == "N") {
                    // if(status?.value == "P" || status?.value == "C") {
                        isDisabled = false
                    // }
                } else {
                    isDisabled = false;
                }
            }

            if (selectedVerificationStatus?.value == "P") {
                if (status?.value !== "R" && status?.value !== "A") {
                    isDisabled = false
                }
            }

            //Allow changing the application status to 'P' or 'C' if the last application status is ‘A’ or ‘N’.
            /*
                if (
                    (selectedApplicationStatus?.value === "A" || selectedApplicationStatus?.value === "N") &&
                    (status.value === "P" || status.value === "C")
                ) {
                    isDisabled = false;
                }
            */


            return {...status, isDisabled};
        });

        setRemapApplOptions(updatedApplOptions);
    }, [data?.verify_status, data?.appln_status, selectedVerificationStatus, selectedApplicationStatus]);

    useEffect(() => {
        getRemarkPA().then((response) => {
            console.log(response);
            const statusReason = response?.data?.data?.map((option) => ({
                label: option.sts_reason,
                value: option.sts_reason,
            }));

            setReasonOptions(statusReason);
        });
    }, []);

    return (
        <React.Fragment>
            <div>
                <Row>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Application Date</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={
                                    data?.appln_date
                                        ? moment(data?.appln_date).format("YYYY-MM-DD HH:mm:ss")
                                        : ""
                                }
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Verification Status</Form.Label>
                            <Select
                                isClearable
                                options={VerificationStatus()}
                                isDisabled={
                                    roleList.includes("FAAS_ADM") ||
                                    roleList.includes("FAAS_TEMP") ||
                                    roleList.includes("FAAS_TEMPA")
                                        ? isDisabled
                                        : true
                                }
                                value={selectedVerificationStatus}
                                onChange={(selectedOption) => {
                                    if (selectedOption == null) {
                                        setSelectedVerificationStatus({
                                            label: "",
                                            value: null,
                                        });
                                    } else {
                                        setSelectedVerificationStatus({
                                            label: selectedOption.label,
                                            value: selectedOption.value,
                                        });

                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Application Status</Form.Label>
                            <Select
                                isClearable
                                options={remapApplOptions}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedApplicationStatus}
                                onChange={(selectedOption) => {
                                    if (selectedOption == null) {
                                        setSelectedApplicationStatus({
                                            label: "",
                                            value: null,
                                        });
                                    } else {

                                        if (
                                            (data?.appln_status === "P" &&
                                                selectedOption.value === "R") ||
                                            (data?.appln_status === "C" && selectedOption.value === "R")
                                        ) {
                                            toast.warning("Unable to Change Status From Pending to Recommended");
                                            return false;
                                        } else if (
                                            selectedOption.value == "R" &&
                                            studentDetail?.studStatus !== "E"
                                        ) {
                                            toast.warning("Student status is not ENROLLED");
                                        } else {
                                            setSelectedApplicationStatus({
                                                label: selectedOption.label,
                                                value: selectedOption.value,
                                            });
                                        }
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>First Verified By / Date</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.verify_status_by ?? "-"}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label></Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={data?.verify_status_date ?? "-"}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Latest File Upload Date / Time</Form.Label>
                            <Form.Control
                                type={`text`}
                                disabled={true}
                                value={"-"}
                                onChange={false}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={8} className="">
                        <Form.Group>
                            <Form.Label>Reason for changes</Form.Label>
                            <Select
                                options={reasonOptions}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedReasonForChanges}
                                onChange={(selectedOption) => {
                                    setSelectedReasonForChanges({
                                        label: `${selectedOption.value} (${selectedOption.label})`,
                                        value: selectedOption.value,
                                    });
                                }}
                            />
                            {/*<Form.Control*/}
                            {/*    type={`text`}*/}
                            {/*    disabled={roleList.includes("FAAS_ADM") ? isDisabled : true}*/}
                            {/*    value={textReasonForChanges}*/}
                            {/*    onChange={(e) => {*/}
                            {/*        setTextReasonForChanges(e.target.value);*/}
                            {/*    }}*/}
                            {/*></Form.Control>*/}
                        </Form.Group>
                    </Col>
                    <Col xs={4} className="">
                        <Form.Group>
                            <Form.Label>Data Flag</Form.Label>
                            <Select
                                options={DataFlagStatus()}
                                isDisabled={
                                    roleList.includes("FAAS_ADM")
                                        ? isDisabled
                                        // ||  (data?.APPLN_STATUS !== "P" && data?.APPLN_STATUS !== "C")
                                        : true
                                }
                                value={selectedDataFlag}
                                onChange={(selectedOption) => {
                                    setSelectedDataFlag({
                                        label: selectedOption.label,
                                        value: selectedOption.value,
                                    });
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Approval Status Remarks (PA)</Form.Label>
                            <Select
                                options={PAOptions}
                                value={selectedPAOption}
                                onChange={(option) => {
                                    if(option){
                                        setSelectedPAOption(option);
                                        setPaRemark(option.value);
                                    }
                                    else{
                                        setSelectedPAOption(null);
                                        setPaRemark("");
                                    }
                                }}
                                isClearable={true}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group className={`d-flex flex-column`}>
                            <Form.Label>Approval Status Remarks (NYP)</Form.Label>
                            <Form.Text className="text-muted">
                                This field will be displayed to students
                            </Form.Text>
                            <Select
                                options={NYPOptions}
                                value={selectedNYPOption}
                                onChange={(option) => {
                                    if(option){
                                        setSelectedNYPOption(option);
                                        setNypRemark(option.value);
                                    }
                                    else{
                                        setSelectedNYPOption(null);
                                        setNypRemark("");
                                    }
                                }}
                                isClearable={true}
                                isDisabled={
                                    roleList.includes("FAAS_ADM") ||
                                    roleList.includes("FAAS_TEMPA")
                                        ? isDisabled
                                        : true
                                }
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Application Remarks</Form.Label>
                            <Form.Control
                                type={`text`}
                                as={"textarea"}
                                disabled={isDisabled}
                                value={data?.remarks1 ?? ""}
                                onChange={(e) => {
                                    handleApplicationChange("remarks1", e.target.value);
                                    setTextApprovalRemarks(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Approval Status Special Remarks</Form.Label>
                            <Form.Text className="text-muted">
                                This field will be displayed to students
                            </Form.Text>
                            <Form.Control
                                type={`text`}
                                as={"textarea"}
                                disabled={isDisabled}
                                value={textApprovalStatusSpecialRemarks ?? ""}
                                onChange={(e) => {
                                    setTextApprovalStatusSpecialRemarks(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={12} className="">
                        <Form.Group>
                            <Form.Label>Special Detail</Form.Label>
                            <Form.Control
                                as="textarea"
                                className="mb-1"
                                type={`text`}
                                disabled={true}
                                value={textDSpecialDetailRemarks ?? ""}
                            ></Form.Control>

                            <Form.Control
                                type={`text`}
                                as={"textarea"}
                                disabled={isDisabled}
                                value={textSpecialDetailRemarks ?? ""}
                                onChange={(e) => {
                                    setTextSpecialDetailRemarks(e.target.value);
                                }}
                            ></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>
                                Recommend Award Type{" "}
                                {data?.appln_status === "R" || data?.appln_status === "A" ? (
                                    <span className={`text-danger`}> *</span>
                                ) : (
                                    ""
                                )}
                            </Form.Label>
                            <Select
                                required={
                                    data?.appln_status === "R" || data?.appln_status === "A"
                                }
                                options={awardTypeList}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedAwardType}
                                isClearable={true}
                                onChange={(selectedOption) => {
                                    if(selectedOption){
                                        setSelectedAwardType({
                                            label: selectedOption.label,
                                            value: selectedOption.value,
                                            code: selectedOption.code,
                                        });
                                    }
                                    else{
                                        setSelectedAwardType({
                                            label: null,
                                            value: null,
                                            code: null,
                                        });
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={6} className="">
                        <Form.Group>
                            <Form.Label>
                                Recommend Award <span className={`text-danger`}> *</span>
                            </Form.Label>
                            <Select
                                required={
                                    data?.appln_status === "R" || data?.appln_status == "A"
                                }
                                options={awardCodeList}
                                isDisabled={roleList.includes("FAAS_ADM") ? isDisabled : true}
                                value={selectedAwardCode}
                                isClearable={true}
                                onChange={(selectedOption) => {
                                    if(selectedOption){
                                        setSelectedAwardCode({
                                            label: selectedOption.label,
                                            value: selectedOption.value,
                                        });
                                    }
                                    else{
                                        setSelectedAwardCode({
                                            label: null,
                                            value: null
                                        });
                                    }
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
}

export default ApplicationDetail;
