import React from "react";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

function EditAndSaveButtonHomes({
                                    isDisabled,
                                    handleClickEdit,
                                    handleSubmitApplicationForm,
                                    handleToggleAll,
                                    handleCancelEdit,
                                    isExpandAll,
                                    isShowCancelButton = true
                                }) {
    return (
        <div className="d-flex justify-content-between">
            {isDisabled ? (
                <button
                    className="bg-white border-1 px-3 rounded-2 "
                    onClick={handleClickEdit}
                >
                    <i className={`bi bi-pencil`}></i>
                    {"  "}Edit
                </button>
            ) : (
                <div className={`d-flex gap-3`}>
                    <button
                        className="bg-white border-1 px-3 rounded-2 text-green-500"
                        onClick={handleSubmitApplicationForm}
                    >
                        <i className={`bi bi-check-lg`}></i>
                        {"  "}Save
                    </button>
                    {
                        isShowCancelButton && (
                        <button
                            className="bg-white border-1 px-3 rounded-2 text-red-500"
                            onClick={handleCancelEdit}
                        >
                            <i className={`bi bi-x-circle`}></i>
                            {"  "}Cancel
                        </button>
                    )
                    }

                </div>
            )}
            <button
                className="bg-white border-1 px-3 rounded-2 "
                onClick={handleToggleAll}
            >
                {!isExpandAll ? <FaChevronDown/> : <FaChevronUp/>}
                {" "}
                {!isExpandAll ? "Expand All" : "Collapse All"}
            </button>

        </div>
    );
}

export default EditAndSaveButtonHomes;
