import React, {useCallback, useEffect, useRef, useState} from "react";
import Layout from "@/components/Layout";
import {Button, Col, Form, FormCheck, Row, Table,} from "@govtechsg/sgds-react";
import Select from "react-select";
import {Breadcrumb} from "@govtechsg/sgds-react/Breadcrumb";
import {getAcademyYear, getExercise} from "@/apis/timeline/api";
import {getApplicantSummary, GetApplications} from "@/apis/reports/api";
import CustomModal from "@/components/CustomModal";
import jsPDF from "jspdf";
import {utils, writeFileXLSX} from "xlsx";
import {toast} from "react-toastify";
import {getCodeTypeSasList, getStudentDetail} from "@/apis/sims/api";
import {getAwardListWithParams} from "@/apis/preparation/setup-award/api";
import {sortDescendingByKey, sortExerciseList} from "@/components/utils/utils";
import {getRemarkNYP,} from "@/apis/preparation/setup-remarks/api";

function PrintApplicationSummary() {
    const refPDF = useRef();
    const refCSV = useRef();
    const headerRef = useRef();
    const reportDataRef = useRef();
    const table1Ref = useRef()
    const table2Ref = useRef()
    const table3Ref = useRef()
    const table4Ref = useRef()
    const table5Ref = useRef()
    const tbl = useRef(null);
    const [showRetrieve, setShowRetrieve] = useState(false);
    const [showModalExport, setShowModalExport] = useState(false);

    const [dataExercise, setDataExercise] = useState([]);
    const [academyYear, setAcademyYear] = useState(null);
    const [exercise, setExercise] = useState(null);
    const [dataAcademyYear, setDataAcademyYear] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loadingAcademyYear, setLoadingAcademyYear] = useState(true);

    const [applicantSummary, setApplicantSummary] = useState({});
    const [totalApplicant, setTotalApplicant] = useState(0);
    const [citizenTotal, setCitizenTotal] = useState({});

    const [disbursed, setDisbursed] = useState([]);
    const [excluded, setExcluded] = useState([]);
    const [unsuccessful, setUnsuccessful] = useState([]);

    const [awardTypeList, setAwardTypeList] = useState([]);
    const [awardCodeList, setAwardCodeList] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        handleGetListAcademyYear();
        handleGetListAwardType();
    }, []);
    useEffect(() => {
        if (academyYear?.value) handleGetListExercise();
    }, [academyYear]);

    useEffect(() => {
        console.log(disbursed);

        let countApplicant = 0;
        let citizenTotals = {};

        disbursed?.map((item) => {
            countApplicant += item.count;

            Object.entries(item.numbers).forEach(([key, value]) => {
                citizenTotals[key] = (citizenTotals[key] || 0) + value;
            });
        });

        applicantSummary?.exclude?.map((item) => {
            countApplicant += item.count;

            Object.entries(item.numbers).forEach(([key, value]) => {
                citizenTotals[key] = (citizenTotals[key] || 0) + value;
            });
        });

        applicantSummary?.unsuccessful?.map((item) => {
            countApplicant += item.count;

            Object.entries(item.numbers).forEach(([key, value]) => {
                citizenTotals[key] = (citizenTotals[key] || 0) + value;
            });
        });

        setTotalApplicant(countApplicant);

        setCitizenTotal(citizenTotals)

    }, [disbursed, applicantSummary]);

    const handleGetListExercise = async () => {
        const params = {
            acad_yr: academyYear?.value,
        };

        try {
            setExercise(null);

            const response = await getExercise(params);

            if (response?.data?.data?.length > 0) {
                setDataExercise(
                    sortExerciseList(response?.data?.data)?.map((item) => ({
                        label: item?.exercise,
                        value: item?.exercise,
                    }))
                );

            }
        } catch (error) {
            console.log(error?.message);
        }
    };

    const getQuantumValue = async (awardType, award) => {
        return getAwardListWithParams(awardType, award).then((resp) => {
            return resp?.data?.data[0]?.quantum;
        });
    };

    const handleRetrieve = async () => {
        setShowRetrieve(true);
        setLoading(true);

        try {
            const [summaryResp, disbursedResp, excludedResp, unsuccessfulResp, totalApplicantResp] = await Promise.all([
                // Fetch all async data in parallel
                getApplicantSummary(academyYear?.value, exercise?.value),
                GetApplications(academyYear?.value, exercise?.value, "", "A"),
                GetApplications(academyYear?.value, exercise?.value, "", "", "E"),
                GetApplications(academyYear?.value, exercise?.value, "", "N"),
                GetApplications(academyYear?.value, exercise?.value),
            ]);

            // Process summary response
            if (summaryResp.status === 200 && summaryResp.data?.data) {
                const content = summaryResp.data.data;
                let excludeArray = [];
                let unsuccessfulArray = [];

                const processApplicants = async (applicants, destinationArray) => {
                    await Promise.all(
                        applicants.map(async (item) => {
                            const studentDetail = await getStudentDetail(item?.f_adm_no);
                            const remarks = await getRemarkNYP(1, 1, item?.code);

                            const studentData = studentDetail?.data?.data?.[0];
                            if (studentData) {
                                const citizenType =
                                    studentData.pr === "Y"
                                        ? "Singaporean/PR"
                                        : studentData?.citizenDesc?.toLowerCase()?.replace(/\b\w/g, (match) =>
                                            match.toUpperCase()
                                        );

                                const existingRemarkIndex = destinationArray.findIndex(
                                    (entry) => entry.remark === (remarks?.data?.data?.[0]?.remark || "No Remark")
                                );

                                if (existingRemarkIndex > -1) {
                                    destinationArray[existingRemarkIndex].count += 1;
                                    destinationArray[existingRemarkIndex].numbers[citizenType] =
                                        (destinationArray[existingRemarkIndex].numbers[citizenType] || 0) + 1;
                                } else {
                                    destinationArray.push({
                                        remark: remarks?.data?.data?.[0]?.remark || "No Remark",
                                        count: 1,
                                        numbers: {[citizenType]: 1},
                                    });
                                }
                            }
                        })
                    );
                };

                await Promise.all([
                    processApplicants(content.exclude || [], excludeArray),
                    processApplicants(content.unsuccessful || [], unsuccessfulArray),
                ]);

                setApplicantSummary({
                    exclude: excludeArray,
                    unsuccessful: unsuccessfulArray,
                });
            }

            // Process disbursed response
            if (disbursedResp.status === 200) {
                const formattedAwardContent = await processAwardContent(disbursedResp);
                setDisbursed(formattedAwardContent);
            }

            // Process excluded response
            if (excludedResp.status === 200 && excludedResp.data?.data) {
                const formattedExcluded = await processAwardContent(excludedResp);
                setExcluded(formattedExcluded);
            }

            // Process unsuccessful response
            if (unsuccessfulResp.status === 200 && unsuccessfulResp.data?.data) {
                const formattedUnsuccessful = await processAwardContent(unsuccessfulResp);
                setUnsuccessful(formattedUnsuccessful);
            }

            // Process total applicants response
            if (totalApplicantResp.status === 200) {
                if (totalApplicantResp.data?.data) {
                    // setTotalApplicant(totalApplicantResp?.data?.totalData);
                }
            }
        } catch (error) {
            console.error("Error retrieving data:", error);
            toast.error("An error occurred while retrieving data.");
        } finally {
            // Set loading to false after all actions are completed
            setLoading(false);
        }
    };

    const processAwardContent = async (resp) => {
        if (resp?.data?.data) {
            let awardContent = [];
            let citizenCounts = {};

            for (const item of resp?.data?.data || []) {
                const studentDetail = await getStudentDetail(item?.adm_no);
                const studentData = studentDetail?.data?.data?.[0];

                if (studentData) {
                    const citizenType =
                        studentData.pr === "Y"
                            ? "Singaporean/PR"
                            : studentData?.citizenDesc?.toLowerCase()?.replace(/\b\w/g, (match) =>
                                match.toUpperCase()
                            );

                    if (!citizenCounts[citizenType]) {
                        citizenCounts[citizenType] = 0;
                    }
                    citizenCounts[citizenType]++;

                    const existingIndex = awardContent.findIndex(
                        (rewardItem) =>
                            item?.rec_award_type === rewardItem?.rec_award_type &&
                            item?.rec_award_code === rewardItem?.rec_award_code
                    );

                    if (existingIndex > -1) {
                        awardContent[existingIndex].count += 1;
                        awardContent[existingIndex].numbers[citizenType] =
                            (awardContent[existingIndex].numbers[citizenType] || 0) + 1;
                    } else {
                        awardContent.push({
                            rec_award_type: item?.rec_award_type,
                            rec_award_code: item?.rec_award_code,
                            count: 1,
                            quantum: 0,
                            numbers: {[citizenType]: 1},
                        });
                    }
                }
            }

            const formattedAwardContent = awardContent.map((award) => ({
                ...award,
                formattedNumbers: Object.entries(award.numbers).map(
                    ([citizenType, count]) => `${citizenType} Count: ${count}`
                ),
            }));

            await Promise.all(
                formattedAwardContent.map(async (item, index) => {
                    try {
                        const quantumValue = await getQuantumValue(item.rec_award_type, item.rec_award_code);
                        formattedAwardContent[index].quantum = quantumValue || 0;

                        const awardTypeResp = await getCodeTypeSasList("AWARDTYPECODE");
                        const typeMatch = awardTypeResp?.data?.data?.find(
                            (content) => content?.code === item.rec_award_type
                        );
                        formattedAwardContent[index].rec_award_type_description = typeMatch?.description || "";

                        const awardCodeResp = await getCodeTypeSasList(item.rec_award_type);
                        const codeMatch = awardCodeResp?.data?.data?.find(
                            (content) => content?.code === item.rec_award_code
                        );
                        formattedAwardContent[index].rec_award_code_description = codeMatch?.description || "";
                    } catch (error) {
                        console.error(`Error processing award content at index ${index}:`, error);
                    }
                })
            );

            return formattedAwardContent;
        }
    };


    const handleGetListAcademyYear = async () => {
        setLoadingAcademyYear(true);

        try {
            setAcademyYear(null);

            const response = await getAcademyYear();
            const academyYears = response?.data?.data?.map((item) => ({
                label: item?.acad_yr,
                value: item?.acad_yr,
            }))
            const sortedAcademyYears = sortDescendingByKey(academyYears, "value");

            setDataAcademyYear(sortedAcademyYears);

        } catch (error) {
            console.log(error?.message);
        } finally {
            setLoadingAcademyYear(false);
        }
    };
    const handleGetListAwardType = async () => {
        getCodeTypeSasList("AWARDTYPECODE").then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setAwardTypeList(resp?.data?.data);
                    }
                }
            }
        });
    };
    const handleGetListAwardCode = (awardTypeCode) => {
        getCodeTypeSasList(awardTypeCode).then((resp) => {
            if (resp?.response?.data?.message) {
                if (
                    resp?.response?.data?.message?.length > 0 &&
                    Array.isArray(resp?.response?.data?.message)
                ) {
                    resp?.response?.data?.message?.map((contentErr) => {
                        toast.error(contentErr?.msg);
                    });
                } else {
                    toast.error(resp?.response?.data?.message);
                }
            } else if (resp?.response?.status == 404) {
                toast.error("Data not found");
            } else {
                if (resp.status == 200) {
                    if (resp?.data?.data) {
                        setAwardCodeList(resp?.data?.data);
                    }
                }
            }
        });
    };
    const exportToXlsx = useCallback(() => {
        const wb = utils.book_new();

        const tableRefs = [
            {ref: table1Ref, name: "Applicants Summary Report"},
            {ref: table2Ref, name: "Total no. of applicants"},
            {ref: table3Ref, name: "No. of Awards to be Disbursed"},
            {ref: table4Ref, name: "Excluded Applicants"},
            {ref: table5Ref, name: "Unsuccessful Applicants"},
        ]

        tableRefs.forEach(({ref, name}) => {
            const elt = ref.current;
            if (elt) {
                const ws = utils.table_to_sheet(elt);
                utils.book_append_sheet(wb, ws, name);
            }
        });

        writeFileXLSX(wb, "applicants-summary.xlsx");
    }, [reportDataRef]);

    const handleExport = () => {
        let optionChecked = "";

        let pdfChecked = refPDF.current.checked;
        let csvChecked = refCSV.current.checked;
        if (pdfChecked) {
            optionChecked = "PDF";
        } else if (csvChecked) {
            optionChecked = "CSV";
        }

        if (optionChecked == "PDF") {
            const doc = new jsPDF({
                format: "a1",
                unit: "px",
                orientation: "landscape",
            });

            doc.setFontSize(10);

            doc.html(reportDataRef.current, {
                callback: (doc) => {
                    doc.save("app-summary.pdf");
                },
                x: 120,
                y: 50,
            });
        } else if (optionChecked == "CSV") {
            exportToXlsx();
        }
    };

    return (
        <Layout currentNav={"reports"}>
            <Breadcrumb
                listProps={{
                    className: "bg-light",
                    foo: "bar",
                }}
            >
                <Breadcrumb.Item>Reports</Breadcrumb.Item>
                <Breadcrumb.Item active>Applicants Summary</Breadcrumb.Item>
            </Breadcrumb>

            <sgds-content-header-top>
                <h2>Print Applicants Summary</h2>
            </sgds-content-header-top>
            <sgds-content-body>
                {" "}
                <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                    <div className="row">
                        <div className="col">
                            <h3>Data Retrieval</h3>
                        </div>
                    </div>

                    <Form
                        className={` d-flex flex-column gap-3 collapse-animation`}
                        // onSubmit={handleSubmit()}
                    >
                        <Row>
                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Academic Year</Form.Label>
                                    <Select
                                        onChange={(value) => setAcademyYear(value)}
                                        options={dataAcademyYear}
                                        placeholder={`Academic Year`}
                                        value={academyYear}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>

                            <Col xs={4}>
                                <Form.Group>
                                    <Form.Label>Exercise</Form.Label>
                                    <Select
                                        isDisabled={!academyYear?.value}
                                        onChange={(value) => setExercise(value)}
                                        options={dataExercise}
                                        value={exercise}
                                        isClearable={true}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <Button
                                    type="button"
                                    onClick={() => handleRetrieve()}
                                    className="btn btn-primary"
                                >
                                    Retrieve
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {loading ?
                    (<div className={`p-3 bg-white shadow-sm border rounded border-1 text-center`}> Loading. . . </div>) : (
                        showRetrieve && <div className={`p-3 bg-white shadow-sm border rounded border-1`}>
                            <Row>
                                <Col xs={12}>
                                    <div className="text-end">
                                        <Button
                                            type="button"
                                            className="btn btn-primary ms-auto mb-5"
                                            onClick={() => setShowModalExport(true)}
                                        >
                                            Export
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                            <div ref={reportDataRef}>
                                <Table id="table-0" ref={table1Ref}>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.DataCell>
                                                <h3 className="text-center py-3 mb-0">
                                                    Applicants Summary Report
                                                </h3>
                                            </Table.DataCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.DataCell className="mb-0">
                                                Financial Assistance Application Exercise
                                            </Table.DataCell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>

                                <Table id="table-1" ref={table2Ref}>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.DataCell
                                                style={{width: "200px"}}
                                                className="border-bottom-0 py-0"
                                            >
                                                Total no. of applicants
                                            </Table.DataCell>
                                            <Table.DataCell
                                                style={{width: "70px"}}
                                                className="border-bottom-0 py-0"
                                            >
                                                {totalApplicant}
                                            </Table.DataCell>
                                        </Table.Row>
                                        {
                                            Object.entries(citizenTotal).map(([key, value]) => (
                                                <Table.Row key={key}>
                                                    <Table.DataCell
                                                        style={{width: "200px"}}
                                                        className="border-bottom-0 py-0"
                                                    ></Table.DataCell>
                                                    <Table.DataCell
                                                        style={{width: "70px"}}
                                                        className="border-bottom-0 py-0"
                                                    ></Table.DataCell>
                                                    <Table.DataCell
                                                        style={{width: "210px"}}
                                                        className="border-bottom-0 py-0"
                                                    >
                                                        {key}
                                                    </Table.DataCell>
                                                    <Table.DataCell className="border-bottom-0 py-0">
                                                        {value}
                                                    </Table.DataCell>
                                                </Table.Row>
                                            ))
                                        }

                                    </Table.Body>
                                </Table>

                                <Table id="table-2" ref={table3Ref}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">
                                                No. of Awards to be Disbursed
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row className="bg-light">
                                            <Table.HeaderCell className="py-0">Award</Table.HeaderCell>
                                            <Table.HeaderCell className="py-0">
                                                Quantum
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="py-0">
                                                Number/Breakdown
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {disbursed?.map((content, index) => {
                                            return (
                                                <Table.Row>
                                                    <Table.DataCell>
                                                        {content?.rec_award_type_description} {" - "} {content?.rec_award_code_description}
                                                    </Table.DataCell>
                                                    <Table.DataCell>{content?.quantum}</Table.DataCell>
                                                    <Table.DataCell>
                                                        {
                                                            content?.formattedNumbers?.map((number, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        {number}
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </Table.DataCell>
                                                </Table.Row>
                                            );
                                        })}

                                        {/* Total */}
                                        <Table.Row>
                                            <Table.DataCell
                                                colSpan={3}
                                                className="py-0 fw-bold text-danger text-sm border-bottom-0"
                                            >
                                                TOTAL NUMBER OF AWARDS TO BE DISBURSED:{" "}
                                                {disbursed?.reduce(
                                                    (acc, current) => acc + Number(current?.count),
                                                    0
                                                )}
                                            </Table.DataCell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.DataCell
                                                colSpan={3}
                                                className="py-0 fw-bold text-danger text-sm border-bottom-0"
                                            >
                                                TOTAL AMOUNT TO BE DISBURSED: $
                                                {Number(disbursed?.reduce(
                                                    (acc, current) => acc + (Number(current?.quantum) * current?.count),
                                                    0
                                                )).toFixed(2)}
                                            </Table.DataCell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>

                                <Table className="mt-5" id="table-3" ref={table4Ref}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">
                                                Excluded Applicants
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row className="bg-light">
                                            <Table.HeaderCell className="py-0">
                                                No. Rejected
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="py-0">
                                                Reason
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="py-0">
                                                Citizen
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {applicantSummary?.exclude?.map((content, index) => {
                                            return (
                                                <Table.Row>
                                                    <Table.DataCell>
                                                        {content?.count}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {content?.remark}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {Object.entries(content.numbers).map(
                                                            ([citizenType, count]) => {
                                                                // Directly use the citizen type as description
                                                                return <div>{`${citizenType} Count: ${count}`}</div>;
                                                            }
                                                        )
                                                        }
                                                    </Table.DataCell>
                                                </Table.Row>
                                            );
                                        })}

                                        {/* Total */}
                                        <Table.Row>
                                            <Table.DataCell className="py-0 fw-bold text-danger border-bottom-0">
                                                TOTAL NO. OF EXCLUDED APPLICANTS:{" "}
                                                {applicantSummary?.exclude?.reduce(
                                                    (acc, current) => acc + parseInt(current.count),
                                                    0
                                                )}
                                            </Table.DataCell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>

                                <Table className="mt-5" id="table-4" ref={table5Ref}>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">
                                                Unsuccessful Applicants
                                            </Table.HeaderCell>
                                        </Table.Row>
                                        <Table.Row className="bg-light">
                                            <Table.HeaderCell className="py-0">
                                                No. Rejected
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="py-0">
                                                Reason
                                            </Table.HeaderCell>
                                            <Table.HeaderCell className="py-0">
                                                Citizen
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {applicantSummary?.unsuccessful?.map((content, index) => {
                                            return (
                                                <Table.Row>
                                                    <Table.DataCell>
                                                        {content?.count}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {content?.remark}
                                                    </Table.DataCell>
                                                    <Table.DataCell>
                                                        {Object.entries(content.numbers).map(
                                                            ([citizenType, count]) => {
                                                                // Directly use the citizen type as description
                                                                return <div>{`${citizenType} Count: ${count}`}</div>;
                                                            }
                                                        )
                                                        }
                                                    </Table.DataCell>
                                                </Table.Row>
                                            );
                                        })}


                                        {/* Total */}
                                        <Table.Row>
                                            <Table.DataCell
                                                colSpan={3}
                                                className="py-0 fw-bold text-danger text-xl border-bottom-0"
                                            >
                                                TOTAL NO. OF UNSUCCESSFUL APPLICANTS:{" "}
                                                {applicantSummary?.unsuccessful?.reduce(
                                                    (acc, current) => acc + current.count,
                                                    0
                                                )}
                                            </Table.DataCell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                            </div>
                        </div>
                    )}
                {/*{showRetrieve && (*/}
                {/*    <div className={`p-3 bg-white shadow-sm border rounded border-1`}>*/}
                {/*        <Row>*/}
                {/*            <Col xs={12}>*/}
                {/*                <div className="text-end">*/}
                {/*                    <Button*/}
                {/*                        type="button"*/}
                {/*                        className="btn btn-primary ms-auto mb-5"*/}
                {/*                        onClick={() => setShowModalExport(true)}*/}
                {/*                    >*/}
                {/*                        Export*/}
                {/*                    </Button>*/}
                {/*                </div>*/}
                {/*            </Col>*/}
                {/*        </Row>*/}
                {/*        <div ref={reportDataRef}>*/}
                {/*            <Table id="table-0" ref={table1Ref}>*/}
                {/*                <Table.Body>*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.DataCell>*/}
                {/*                            <h3 className="text-center py-3 mb-0">*/}
                {/*                                Applicants Summary Report*/}
                {/*                            </h3>*/}
                {/*                        </Table.DataCell>*/}
                {/*                    </Table.Row>*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.DataCell className="mb-0">*/}
                {/*                            Financial Assistance Application Exercise*/}
                {/*                        </Table.DataCell>*/}
                {/*                    </Table.Row>*/}
                {/*                </Table.Body>*/}
                {/*            </Table>*/}

                {/*            <Table id="table-1" ref={table2Ref}>*/}
                {/*                <Table.Body>*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.DataCell*/}
                {/*                            style={{width: "200px"}}*/}
                {/*                            className="border-bottom-0 py-0"*/}
                {/*                        >*/}
                {/*                            Total no. of applicants*/}
                {/*                        </Table.DataCell>*/}
                {/*                        <Table.DataCell*/}
                {/*                            style={{width: "70px"}}*/}
                {/*                            className="border-bottom-0 py-0"*/}
                {/*                        >*/}
                {/*                            {totalApplicant}*/}
                {/*                        </Table.DataCell>*/}
                {/*                    </Table.Row>*/}
                {/*                    {*/}
                {/*                        Object.entries(citizenTotal).map(([key, value]) => (*/}
                {/*                            <Table.Row key={key}>*/}
                {/*                                <Table.DataCell*/}
                {/*                                    style={{width: "200px"}}*/}
                {/*                                    className="border-bottom-0 py-0"*/}
                {/*                                ></Table.DataCell>*/}
                {/*                                <Table.DataCell*/}
                {/*                                    style={{width: "70px"}}*/}
                {/*                                    className="border-bottom-0 py-0"*/}
                {/*                                ></Table.DataCell>*/}
                {/*                                <Table.DataCell*/}
                {/*                                    style={{width: "210px"}}*/}
                {/*                                    className="border-bottom-0 py-0"*/}
                {/*                                >*/}
                {/*                                    {key}*/}
                {/*                                </Table.DataCell>*/}
                {/*                                <Table.DataCell className="border-bottom-0 py-0">*/}
                {/*                                    {value}*/}
                {/*                                </Table.DataCell>*/}
                {/*                            </Table.Row>*/}
                {/*                        ))*/}
                {/*                    }*/}

                {/*                </Table.Body>*/}
                {/*            </Table>*/}

                {/*            <Table id="table-2" ref={table3Ref}>*/}
                {/*                <Table.Header>*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">*/}
                {/*                            No. of Awards to be Disbursed*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                    </Table.Row>*/}
                {/*                    <Table.Row className="bg-light">*/}
                {/*                        <Table.HeaderCell className="py-0">Award</Table.HeaderCell>*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            Quantum*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            Number/Breakdown*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                    </Table.Row>*/}
                {/*                </Table.Header>*/}
                {/*                <Table.Body>*/}
                {/*                    {disbursed?.map((content, index) => {*/}
                {/*                        return (*/}
                {/*                            <Table.Row>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {content?.rec_award_type_description} {" - "} {content?.rec_award_code_description}*/}
                {/*                                </Table.DataCell>*/}
                {/*                                <Table.DataCell>{content?.quantum}</Table.DataCell>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {*/}
                {/*                                        content?.formattedNumbers?.map((number, index) => {*/}
                {/*                                            return (*/}
                {/*                                                <div key={index}>*/}
                {/*                                                    {number}*/}
                {/*                                                </div>*/}
                {/*                                            );*/}
                {/*                                        })*/}
                {/*                                    }*/}
                {/*                                </Table.DataCell>*/}
                {/*                            </Table.Row>*/}
                {/*                        );*/}
                {/*                    })}*/}

                {/*                    /!* Total *!/*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.DataCell*/}
                {/*                            colSpan={3}*/}
                {/*                            className="py-0 fw-bold text-danger text-sm border-bottom-0"*/}
                {/*                        >*/}
                {/*                            TOTAL NUMBER OF AWARDS TO BE DISBURSED:{" "}*/}
                {/*                            {disbursed?.reduce(*/}
                {/*                                (acc, current) => acc + Number(current?.count),*/}
                {/*                                0*/}
                {/*                            )}*/}
                {/*                        </Table.DataCell>*/}
                {/*                    </Table.Row>*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.DataCell*/}
                {/*                            colSpan={3}*/}
                {/*                            className="py-0 fw-bold text-danger text-sm border-bottom-0"*/}
                {/*                        >*/}
                {/*                            TOTAL AMOUNT TO BE DISBURSED: $*/}
                {/*                            {Number(disbursed?.reduce(*/}
                {/*                                (acc, current) => acc + (Number(current?.quantum) * current?.count),*/}
                {/*                                0*/}
                {/*                            )).toFixed(2)}*/}
                {/*                        </Table.DataCell>*/}
                {/*                    </Table.Row>*/}
                {/*                </Table.Body>*/}
                {/*            </Table>*/}

                {/*            <Table className="mt-5" id="table-3" ref={table4Ref}>*/}
                {/*                <Table.Header>*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">*/}
                {/*                            Excluded Applicants*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                    </Table.Row>*/}
                {/*                    <Table.Row className="bg-light">*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            No. Rejected*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            Reason*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            Citizen*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                    </Table.Row>*/}
                {/*                </Table.Header>*/}
                {/*                <Table.Body>*/}
                {/*                    {applicantSummary?.exclude?.map((content, index) => {*/}
                {/*                        return (*/}
                {/*                            <Table.Row>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {content?.count}*/}
                {/*                                </Table.DataCell>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {content?.remark}*/}
                {/*                                </Table.DataCell>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {Object.entries(content.numbers).map(*/}
                {/*                                        ([citizenType, count]) => {*/}
                {/*                                            // Directly use the citizen type as description*/}
                {/*                                            return <div>{`${citizenType} Count: ${count}`}</div>;*/}
                {/*                                        }*/}
                {/*                                    )*/}
                {/*                                    }*/}
                {/*                                </Table.DataCell>*/}
                {/*                            </Table.Row>*/}
                {/*                        );*/}
                {/*                    })}*/}

                {/*                    /!* Total *!/*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.DataCell className="py-0 fw-bold text-danger border-bottom-0">*/}
                {/*                            TOTAL NO. OF EXCLUDED APPLICANTS:{" "}*/}
                {/*                            {applicantSummary?.exclude?.reduce(*/}
                {/*                                (acc, current) => acc + parseInt(current.count),*/}
                {/*                                0*/}
                {/*                            )}*/}
                {/*                        </Table.DataCell>*/}
                {/*                    </Table.Row>*/}
                {/*                </Table.Body>*/}
                {/*            </Table>*/}

                {/*            <Table className="mt-5" id="table-4" ref={table5Ref}>*/}
                {/*                <Table.Header>*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.HeaderCell className="py-0 border-bottom-0 text-sm text-primary">*/}
                {/*                            Unsuccessful Applicants*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                    </Table.Row>*/}
                {/*                    <Table.Row className="bg-light">*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            No. Rejected*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            Reason*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                        <Table.HeaderCell className="py-0">*/}
                {/*                            Citizen*/}
                {/*                        </Table.HeaderCell>*/}
                {/*                    </Table.Row>*/}
                {/*                </Table.Header>*/}
                {/*                <Table.Body>*/}
                {/*                    {applicantSummary?.unsuccessful?.map((content, index) => {*/}
                {/*                        return (*/}
                {/*                            <Table.Row>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {content?.count}*/}
                {/*                                </Table.DataCell>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {content?.remark}*/}
                {/*                                </Table.DataCell>*/}
                {/*                                <Table.DataCell>*/}
                {/*                                    {Object.entries(content.numbers).map(*/}
                {/*                                        ([citizenType, count]) => {*/}
                {/*                                            // Directly use the citizen type as description*/}
                {/*                                            return <div>{`${citizenType} Count: ${count}`}</div>;*/}
                {/*                                        }*/}
                {/*                                    )*/}
                {/*                                    }*/}
                {/*                                </Table.DataCell>*/}
                {/*                            </Table.Row>*/}
                {/*                        );*/}
                {/*                    })}*/}


                {/*                    /!* Total *!/*/}
                {/*                    <Table.Row>*/}
                {/*                        <Table.DataCell*/}
                {/*                            colSpan={3}*/}
                {/*                            className="py-0 fw-bold text-danger text-xl border-bottom-0"*/}
                {/*                        >*/}
                {/*                            TOTAL NO. OF UNSUCCESSFUL APPLICANTS:{" "}*/}
                {/*                            {applicantSummary?.unsuccessful?.reduce(*/}
                {/*                                (acc, current) => acc + current.count,*/}
                {/*                                0*/}
                {/*                            )}*/}
                {/*                        </Table.DataCell>*/}
                {/*                    </Table.Row>*/}
                {/*                </Table.Body>*/}
                {/*            </Table>*/}
                {/*        </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                <CustomModal
                    show={showModalExport}
                    title={`Export`}
                    handleClose={() => setShowModalExport(false)}
                    modalSize={"md"}
                    withIcon={false}
                    description={
                        <div>
                            <div>
                                <b>Export as</b>
                            </div>
                            <div>
                                <FormCheck
                                    type="radio"
                                    name="export"
                                    label="PDF"
                                    value="pdf"
                                    id="pdf"
                                    ref={refPDF}
                                />
                                <FormCheck
                                    ref={refCSV}
                                    type="radio"
                                    name="export"
                                    label="CSV"
                                    value="csv"
                                    id="csv"
                                />
                            </div>
                        </div>
                    }
                    submitButtonText={`Export`}
                    action={handleExport}
                />
            </sgds-content-body>
        </Layout>
    );
}

export default PrintApplicationSummary;
