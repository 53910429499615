import {Accordion} from "@govtechsg/sgds-react/Accordion";
import {Col, Form, FormCheck, Row} from "@govtechsg/sgds-react";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {getCodeList} from "@/apis/preparation/setup-dialect-etc/api";
import {LivingAllowance} from "@/apis/app-records/maintenance/status";
import FormulaModal from "@/components/FormulaModal";

const genderOptions = [
    {value: "M", label: "Male"},
    {value: "F", label: "Female"},
];

export default function OriginalDetails({
                                            isDisabled,
                                            applicationDetail,
                                            listMTCRequestResult,
                                            listMTCDetails,
                                            listMTIncomes,
                                            listMTConsents,
                                            listMTCases,
                                            listMTCaseNotes,
                                            listMTCDetailsOriginal,
                                            listOriginalDetails,
                                            specialMeansStatusOptions,
                                            maritalStatusOptions,
                                            residentialOptions,
                                            consentTypeOptions,
                                            consentScopeOptions,
                                            consentStatusOptions,
                                            legalCapacityOptions,
                                            relationshipOptions,
                                            occupationOptions,
                                            details,
                                            listMTCDetailsZ,
                                            activeKeys,
                                            handleToggleAccordion,
                                            listScholarshipLog,
                                        }) {

    const [relationshipList, setRelationshipList] = useState([]);
    const [maritalList, setMaritalList] = useState([]);
    const [housingList, setHousingList] = useState([]);
    const [dialectList, setDialectList] = useState([]);
    const [showFormulaModal, setShowFormulaModal] = useState(false);

    const handleGetRelationshipStatus = () => {
        getCodeList("R", 0)
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            description: `${item?.description}`,
                        });
                    });
                    setRelationshipList(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleGetMaritalStatus = () => {
        getCodeList("MS", 0)
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            description: `${item?.description}`,
                        });
                    });
                    setMaritalList(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleGetHousingStatus = () => {
        getCodeList("H", 0)
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            description: `${item?.description}`,
                        });
                    });
                    setHousingList(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleGetDialectStatus = () => {
        getCodeList("DG", 0)
            .then((response) => {
                if (response.status == 200) {
                    let listContent = [];
                    response?.data?.data?.map((item) => {
                        listContent.push({
                            value: item?.code,
                            description: `${item?.description}`,
                        });
                    });
                    setDialectList(listContent);
                }
            })
            .catch((err) => {
                console.error(err);
            });
    };

    useEffect(() => {
        console.log(listScholarshipLog)
    },[listScholarshipLog])

    useEffect(() => {
        handleGetRelationshipStatus();
        handleGetMaritalStatus();
        handleGetHousingStatus();
        handleGetDialectStatus();
    }, []);

    return (
        <>
            <div className="">
                <Accordion key="16" activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="16" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("16")}>
                            Family Members
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                {
                                    listMTCDetailsZ?.map((detail, index) => {
                                        return (
                                            <Row>
                                                <Col xs={12} className={`fw-bold`}>
                                                    #{index + 1} Member{" "}
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.hh_name ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "hh_name", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>
                                                            NRIC/Fin/Passport No. (if foreigner)
                                                            <span className={`text-danger`}> *</span>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled={true}
                                                            value={detail?.hh_uin ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "hh_uin", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group className={`d-flex flex-column`}>
                                                        <Form.Label>Date of Birth</Form.Label>
                                                        <DatePicker
                                                            selected={detail?.dob == undefined ? null : detail?.dob}
                                                            className={`form-control`}
                                                            onChange={(date) => {
                                                                if (date == null) {
                                                                    handleChange(index, "dob", "");
                                                                } else {
                                                                    handleChange(
                                                                        index,
                                                                        "dob",
                                                                        moment(date).format("YYYY-MM-DD")
                                                                    );
                                                                }
                                                            }}
                                                            dateFormat="dd/MM/yyyy"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            disabled={true}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Age</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            disabled={true}
                                                            value={detail?.dob && Math.floor((new Date() - new Date(detail?.dob)) / (365.25 * 24 * 60 * 60 * 1000))}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Gender</Form.Label>
                                                        <Select
                                                            options={genderOptions}
                                                            isDisabled={true}
                                                            value={{
                                                                value: detail?.gender,
                                                                label:
                                                                    detail?.gender == "M" ? "Male" : "Female",
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "gender",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Relationship Status</Form.Label>
                                                        <Select
                                                            options={relationshipOptions}
                                                            isDisabled={true}
                                                            value={relationshipOptions.find(option => option.value === detail?.relationship)}
                                                            // value={{
                                                            //     value: detail?.RELATIONSHIP,
                                                            //     label: detail?.RELATIONSHIP,
                                                            // }}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "relationship",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Residental Status</Form.Label>
                                                        <Select
                                                            options={residentialOptions}
                                                            value={residentialOptions.find(option => option.value === detail?.res_status)}
                                                            isDisabled={true}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "res_status",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Martial Status</Form.Label>
                                                        <Select
                                                            value={maritalStatusOptions.find(option => option.value === detail?.marital_status)}
                                                            options={maritalStatusOptions}
                                                            isDisabled={true}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "marital_status",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                                    Income Details
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Occupation</Form.Label>
                                                        <Select
                                                            options={occupationOptions}
                                                            value={occupationOptions.find(option => option.value === detail?.occupation)}
                                                            isDisabled={true}
                                                            onChange={(selectedOption) => {
                                                                handleChange(
                                                                    index,
                                                                    "occupation",
                                                                    selectedOption.value
                                                                );
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Avg Monthly Income</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.avg_mthly_income ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "avg_mthly_income",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Other income</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.other_income ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "other_income",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Remarks</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            Reason if occupation status is unemployed
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.remarks ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "remarks", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={6} className="">
                                                    <Form.Group>
                                                        <Form.Label>Special Means Status</Form.Label>
                                                        <Select
                                                            isDisabled={true}
                                                            options={specialMeansStatusOptions}
                                                            value={specialMeansStatusOptions.find(option => option.value === detail?.special_means_status)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "special_means_status",
                                                                    e.value
                                                                )
                                                            }
                                                        />
                                                    </Form.Group>
                                                </Col>

                                                <Col xs={12} className={`fw-bold my-3 text-blue`}>
                                                    Consent Details
                                                </Col>
                                                <Col xs={12} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent</Form.Label>
                                                        <Form.Text className="text-muted">
                                                            If no, please specify Consent NRIC and Name
                                                        </Form.Text>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.reusable_consentscope ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "reusable_consentscope",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent ID</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.reusable_consentid ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "reusable_consentid",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Reusable Consent Scope</Form.Label>
                                                        <Select
                                                            isDisabled={true}
                                                            options={consentScopeOptions}
                                                            value={consentScopeOptions.find(option => option.value === applicationDetail?.reusable_consentscope)}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "reusable_consentscope",
                                                                    e.value
                                                                )
                                                            }
                                                        />
                                                        {/*<Form.Control*/}
                                                        {/*    type={`text`}*/}
                                                        {/*    disabled={true}*/}
                                                        {/*    value={detail?.REUSABLE_CONSENTSCOPE}*/}
                                                        {/*    onChange={(e) =>*/}
                                                        {/*        handleChange(*/}
                                                        {/*            index,*/}
                                                        {/*            "REUSABLE_CONSENTSCOPE",*/}
                                                        {/*            e.target.value*/}
                                                        {/*        )*/}
                                                        {/*    }*/}
                                                        {/*></Form.Control>*/}
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4} className="">
                                                    <Form.Group>
                                                        <Form.Label>Is No Valid Auth</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.isnovalidauth ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(
                                                                    index,
                                                                    "isnovalidauth",
                                                                    e.target.value
                                                                )
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>

                                                <h5 className={`mt-5`}>Consent Detail</h5>


                                                {detail?.mt_consent_details?.map((condetail, detailIndex) => (
                                                    <Row key={detailIndex}>
                                                        <Col xs={12}>
                                                            Consent Detail #{detailIndex + 1}
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Type</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={consentTypeOptions}
                                                                    value={consentTypeOptions.find(option => option.value === condetail?.CONSENT_TYPE)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.consent_type",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_TYPE}*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.CONSENT_TYPE",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Scope</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={consentScopeOptions}
                                                                    value={consentScopeOptions.find(option => option.value === condetail?.consent_scope)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.consent_scope",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={detail?.MT_CONSENT_DETAILS?.CONSENT_SCOPE}*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.CONSENT_SCOPE",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Legal Capacity</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={legalCapacityOptions}
                                                                    value={legalCapacityOptions.find(option => option.value === condetail?.legal_capacity)}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.legal_capacity",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={*/}
                                                                {/*        detail?.MT_CONSENT_DETAILS?.LEGAL_CAPACITY*/}
                                                                {/*    }*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.LEGAL_CAPACITY",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Manual Consent</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_manual_consent ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_manual_consent",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent NRIC</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_id ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_id",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Name</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_name ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_name",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Email</Form.Label>
                                                                <Form.Control
                                                                    type={`email`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_email ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_email",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Status</Form.Label>
                                                                <Select
                                                                    isDisabled={true}
                                                                    options={consentStatusOptions}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_status",
                                                                            e.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                />
                                                                {/*<Form.Control*/}
                                                                {/*    type={`text`}*/}
                                                                {/*    disabled={isDisabled && detail.MTC_TAG !== "D"}*/}
                                                                {/*    value={*/}
                                                                {/*        detail?.MT_CONSENT_DETAILS?.NYP_CONSENT_STATUS*/}
                                                                {/*    }*/}
                                                                {/*    onChange={(e) =>*/}
                                                                {/*        handleChange(*/}
                                                                {/*            index,*/}
                                                                {/*            "MT_CONSENT_DETAILS.NYP_CONSENT_STATUS",*/}
                                                                {/*            e.target.value*/}
                                                                {/*        )*/}
                                                                {/*    }*/}
                                                                {/*></Form.Control>*/}
                                                            </Form.Group>
                                                        </Col>

                                                        <Col xs={6}>
                                                            <Form.Group>
                                                                <Form.Label>Consent Date</Form.Label>
                                                                <Form.Control
                                                                    type={`text`}
                                                                    disabled={true}
                                                                    value={
                                                                        condetail?.nyp_consent_date ?? ""
                                                                    }
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            index,
                                                                            "mt_consent_details.nyp_consent_date",
                                                                            e.target.value,
                                                                            detailIndex
                                                                        )
                                                                    }
                                                                ></Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                ))}

                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>Remarks</Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={detail?.remarks ?? ""}
                                                            onChange={(e) =>
                                                                handleChange(index, "remarks", e.target.value)
                                                            }
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                            </Row>

                                        )
                                    })
                                }
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion key={"22"} activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="22" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("22")}>
                            Applicant
                        </Accordion.Header>
                        <Accordion.Body>
                            <div>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>Marital Status</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={
                                                    maritalList.find(
                                                        (item) => item.value === details?.marital_status
                                                    )?.description || "-"
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>Dialect Group</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={
                                                    dialectList.find(
                                                        (item) => item.value === details?.dialect_grp
                                                    )?.description || "-"
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={4}>
                                        <Form.Group>
                                            <Form.Label>Housing Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={
                                                    housingList.find(
                                                        (item) => item.value === details?.homehousetype
                                                    )?.description || "-"
                                                }
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Applicant's Gross Monthly Income</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={details?.mthly_income ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Applicant's Other Income</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={details?.other_income ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>GHI</Form.Label>
                                            <Form.Text className="text-muted">
                                                Gross Household Income
                                            </Form.Text>

                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={details?.gross_income ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>MPCI</Form.Label>
                                            <Form.Text className="text-muted">
                                                Monthly Per Capital Income
                                            </Form.Text>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={details?.mpci ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12}>
                                        <Form.Group>
                                            <Form.Label>Number of Family</Form.Label>
                                            <Form.Control
                                                type="text"
                                                disabled={true}
                                                value={details?.family_members ?? ""}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} className="mt-4">
                                        <button
                                            className={"btn btn-clear"}
                                            onClick={() => setShowFormulaModal(true)}
                                        >
                                            <u>Check formula note</u>
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>

            <div className="mt-4">
                <Accordion key={"23"} activeKey={activeKeys} className="">
                    <Accordion.Item eventKey="23" className="shadow border-0">
                        <Accordion.Header className="header-text text-blue" onClick={() => handleToggleAccordion("23")}>
                            Sponsorship / Government Bursary
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className="mt-3">
                                {listScholarshipLog?.some((item) => item.rec_tag !== "B") && <h3>Scholarship</h3>}
                                {listScholarshipLog?.map((item, index) => {
                                    if (item?.rec_tag !== "B") {
                                        return (
                                            <Row>
                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            Name of Scholarship / Sponsorship
                                                        </Form.Label>
                                                        <Form.Control
                                                            type={`text`}
                                                            disabled={true}
                                                            value={item?.scholar_sponsor ?? ""}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={12}>
                                                    <Form.Group>
                                                        <FormCheck
                                                            id={`tuition_fee${index}`}
                                                            label="Tuition Fees"
                                                            name="tuition_fee "
                                                            type="checkbox"
                                                            checked={item?.tutfee_c_tag === "Y"}
                                                            disabled={true}
                                                        />
                                                        <FormCheck
                                                            id={`living_allowance${index}`}
                                                            label="Living Allowance"
                                                            name="living_allowance"
                                                            type="checkbox"
                                                            checked={item?.l_allowance_c_tag === "Y"}
                                                            disabled={true}
                                                        />
                                                        {item?.l_allowance_c_tag === "Y" && (
                                                            <Row className="ms-md-3">
                                                                <Col xs={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Amount</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            disabled={true}
                                                                            min={0}
                                                                            value={item?.l_allowance_c_details ?? ""}
                                                                        ></Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>Type</Form.Label>
                                                                        <Select
                                                                            options={LivingAllowance()}
                                                                            isDisabled={true}
                                                                            value={LivingAllowance().find(
                                                                                (option) =>
                                                                                    option.value ===
                                                                                    item?.l_allowance_c_type
                                                                            )}
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                        <FormCheck
                                                            id={`others${index}`}
                                                            label="Others (please specify)"
                                                            name="others "
                                                            type="checkbox"
                                                            checked={item?.others_c_tag === "Y"}
                                                            disabled={true}
                                                        />

                                                        {item?.others_c_tag === "Y" && (
                                                            <Row className="ms-md-3">
                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label>
                                                                            Other coverage details
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            type="text"
                                                                            value={item?.others_c_details ?? ""}
                                                                            disabled={true}
                                                                        ></Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        );
                                    }
                                })}
                            </div>

                            <div className="mt-3">
                                {listScholarshipLog?.some((item) => item.rec_tag === "B") &&
                                    <h3>Government Bursary</h3>}
                                {listScholarshipLog?.map((item, index) => {
                                    if (item?.rec_tag == "B") {
                                        return (
                                            <div className="mt-3" key={index}>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                Name of Government Bursary
                                                            </Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={true}
                                                                value={item?.scholar_sponsor ?? ""}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group>
                                                            {" "}
                                                            <Form.Label>Quantum</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={true}
                                                                value={item?.govbur_quantum ?? ""}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Form.Group className={`d-flex flex-column`}>
                                                            {" "}
                                                            <Form.Label>Period</Form.Label>
                                                            <Form.Control
                                                                type={`text`}
                                                                disabled={true}
                                                                value={item?.govbur_period ?? ""}
                                                            ></Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
            <FormulaModal
                show={showFormulaModal}
                handleClose={() => setShowFormulaModal(!showFormulaModal)}
            />
        </>
    )
}
